import auth from "./auth";
import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/home";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import ChangePassword from "./views/change-password"
import MRPD from "./views/_transaction/MRPD";
import MVUR from "./views/_transaction/MVUR";
import MD3T from "./views/_transaction/MD3T";
import AUSR from "./views/_transaction/AUSR";
import AVUL from "./views/_transaction/AVUL";
import ABIF from "./views/_transaction/ABIF";
import ADRI from "./views/_transaction/ADRI";
import ASMT from "./views/_transaction/ASMT";
import MRPM from "./views/_transaction/MRPM";
import AVML from "./views/_transaction/AVML";
import APRT from "./views/_transaction/APRT";
import MD2T from "./views/_transaction/MD2T";
import AMGR from "./views/_transaction/AMGR";
import MATC from "./views/_transaction/MATC";
import MPSH from "./views/_transaction/MPSH";
import AMAK from "./views/_transaction/AMAK";
import AIER from "./views/_transaction/AIER";
import MRCV from "./views/_transaction/MRCV";
import MPHL from "./views/_transaction/MPHL";
import MMMG from "./views/_transaction/MMMG";
import AVLJ from "./views/_transaction/AVLJ";
import V from "./views/v-form"; //의약품 안전카드 보기
import IV from "./views/iv-form"; //(신) 환자 PDF 조회
import A from "./views/a-form"; //개인정보 활용 동의
import R from "./views/r-form"; //수기 발행용 qr 현재는 미구현

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        transName: "Home",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/check-otp",
      name: "check-otp",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "OTP 확인"
      },
      component: loadView("check-otp-form")
    },
    {
      path: "/verify-email",
      name: "verify-email",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "이메일 인증"
      },
      component: loadView("verify-email-form")
    },
    {
      path: "/otp-confirm",
      name: "otp-confirm",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "OTP 설정"
      },
      component: loadView("otp-confirm-form")
    },
    {
      path: "/wait-confirm",
      name: "wait-confirm",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "관리자 승인 대기"
      },
      component: loadView("wait-confirm-form")
    },
    {
      path: "/find-id",
      name: "find-id",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "ID 찾기"
      },
      component: loadView("find-id-form")
    },
    {
      path: "/find-password",
      name: "find-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "비밀번호 찾기"
      },
      component: loadView("find-password-form")
    },
    {
      path: "/change-password",
      name: "change-password",
      meta: {
        transName: "비밀번호 변경",
        requiresAuth: false,
        layout: defaultLayout
      },
      component: ChangePassword
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    },
    {
      path: "/ausr",
      name: "ausr",
      meta: {
        transName: "사용자 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AUSR
    },
    {
      path: "/amgr",
      name: "amgr",
      meta: {
        transName: "메뉴 권한 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AMGR
    },
    {
      path: "/avul",
      name: "avul",
      meta: {
        transName: "로그인 이력",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AVUL
    },
    {
      path: "/avml",
      name: "avml",
      meta: {
        transName: "기관관리자 사용 이력",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AVML
    },
    {
      path: "/abif",
      name: "abif",
      meta: {
        transName: "요양기관 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ABIF
    },
    {
      path: "/adri",
      name: "adri",
      meta: {
        transName: "약물 정보(KD, ATC)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ADRI
    },
    {
      path: "/asmt",
      name: "asmt",
      meta: {
        transName: "증상 정보(MedDRA)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ASMT
    },
    {
      path: "/mrpd",
      name: "mrpd",
      meta: {
        transName: "부작용 정보 등록",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MRPD
    },
    {
      path: "/mvur",
      name: "mvur",
      meta: {
        transName: "등록 환자 조회",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MVUR
    },
    {
      path: "/mrpm",
      name: "mrpm",
      meta: {
        transName: "부작용 정보 조회",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MRPM
    },
    {
      path: "/mphl",
      name: "mphl",
      meta: {
        transName: "약물 카드 인쇄 이력(PC 저장)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MPHL
    },
    {
      path: "/aprt",
      name: "aprt",
      meta: {
        transName: "약물 별 출력 카드 설정",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: APRT
    },
    {
      path: "/md2t",
      name: "md2t",
      meta: {
        transName: "약물 통합정보 Template 설정",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MD2T
    },
    {
      path: "/matc",
      name: "matc",
      meta: {
        transName: "ATC 정보",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MATC
    },
    {
      path: "/mpsh",
      name: "mpsh",
      meta: {
        transName: "약물 카드 인쇄/전송 이력",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MPSH
    },
    {
      path: "/md3t",
      name: "md3t",
      meta: {
        transName: "의료기관정보 Template 설정",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MD3T
    },
    {
      path: "/amak",
      name: "amak",
      meta: {
        transName: "API Key 관리",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AMAK
    },
    {
      path: "/aier",
      name: "aier",
      meta: {
        transName: "Interface Error 이력",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AIER
    },
    {
      path: "/mrcv",
      name: "mrcv",
      meta: {
        transName: "수신함",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MRCV
    },
    {
      path: "/mmmg",
      name: "mmmg",
      meta: {
        transName: "복약지도문",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MMMG
    },
    {
      path: "/avlj",
      name: "avlj",
      meta: {
        transName: "LLM 평가",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AVLJ
    },
    {
      path: "/create-account",
      name: "create-account",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign Up"
      },
      component: loadView("create-account-form"),
    },
    {
      path: "/v",
      name: "v",
      meta: {
        requiresAuth: false,
        layout: V
      }
    },
    {
      path: "/p",
      name: "p",
      meta: {
        requiresAuth: false,
        layout: IV
      }
    },
    {
      path: "/a",
      name: "a",
      meta: {
        requiresAuth: false,
        layout: A
      }
    },
    {
      path: "/r",
      name: "r",
      meta: {
        requiresAuth: false,
        layout: R
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name == "logout") {
    next();
  }

  auth.loggedIn(to.name, 'Y').then(loggedIn => {
    if (loggedIn == 'SUCCESS' && (to.name === "login-form" || to.name === "verify-email" || to.name === "wait-confirm" || to.name === "otp-confirm" || to.name === "check-otp")) {
      next({ name: "home" });
    }

    if (loggedIn == 'VERIFY_EMAIL' && to.name != "verify-email") {
      next({ name: "verify-email" });
    }

    if (loggedIn == 'OTP_CONFIRM' && to.name != "otp-confirm") {
      next({ name: "otp-confirm" });
    }

    if (loggedIn == 'WAIT_CONFIRM' && to.name != "wait-confirm") {
      next({ name: "wait-confirm" });
    }

    if (loggedIn == 'CHECK_OTP' && to.name != "check-otp") {
      next({ name: "check-otp" });
    }


    if (!loggedIn && to.name != "login-form" && to.name != "create-account" && to.name != "find-id" && to.name != "find-password" && to.name != "iv" && to.name != "p" && to.name != "v" && to.name != "a" && to.name != "r") {
      next({ name: "login-form" });
    }

    if (loggedIn && to.name == "login-form") {
      auth.logOut();
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (loggedIn == 'VERIFY_EMAIL') {
        next({ name: "verify-email" });
      } else if (loggedIn == 'OTP_CONFIRM') {
        next({ name: "otp-confirm" });
      } else if (loggedIn == 'WAIT_CONFIRM') {
        next({ name: "wait-confirm" });
      } else if (loggedIn == 'CHECK_OTP') {
        next({ name: "check-otp" });
      } else if (loggedIn == 'NO_AUTH') {
        next({ name: "home" });

      } else if (loggedIn != 'SUCCESS') {
        auth.logOut();
        next({ name: "login-form" });
      } else {
        next();
      }
    } else {
      next();
    }
  });
});

export default router;
