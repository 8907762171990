<template>
  <div id="root">
    <div :class="cssClasses">
      <component
        :is="$route.meta.layout"
        :title="title"
        :transName="transName"
        :is-x-small="screen.getScreenSizeInfo.isXSmall"
        :is-large="screen.getScreenSizeInfo.isLarge"
        :newUserCnt="newUserCnt"
        :adrDataIFCnt="adrDataIFCnt"
        :mgDataIFCnt="mgDataIFCnt"
      >
        <div class="content">
          <router-view @refreshMenu="refreshMenu"></router-view>
        </div>
        <template #footer>
          <app-footer />
        </template>
      </component>
    </div>
  </div>
</template>

<script>
import AppFooter from './components/app-footer';
import { sizes, subscribe, unsubscribe } from './utils/media-query';
import {
  getCurrentInstance,
  reactive,
  onMounted,
  onBeforeUnmount,
  computed,
} from 'vue';
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';
import { loadMessages, locale } from 'devextreme/localization';
import { default_message } from './utils/message_kr';
import auth from './auth';
import dsu from './utils/dataSourceUtils';

function getScreenSizeInfo() {
  const screenSizes = sizes();

  return {
    isXSmall: screenSizes['screen-x-small'],
    isLarge: screenSizes['screen-large'],
    cssClasses: Object.keys(screenSizes).filter((cl) => screenSizes[cl]),
  };
}

setInterval(function () {
  auth.loggedIn('', 'N');
}, 5 * 60 * 1000); //5분

export default {
  components: {
    AppFooter,
  },
  created() {
    locale('ko-KR');
    loadMessages({
      'ko-KR': default_message,
    });
  },
  updated() {
    this.refreshMenu();
  },
  methods: {
    refreshMenu() {
      dsu.getAxiosOBJGet('/api/selectNewUserCnt').then((res) => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          this.newUserCnt = response.data;
        }
      });

      dsu.getAxiosOBJGet('/api/MRCV/selectADRDataIFCnt').then((res) => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          this.adrDataIFCnt = response.data;
        }
      });

      dsu.getAxiosOBJGet('/api/MMMG/selectMGDataIFCnt').then((res) => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          this.mgDataIFCnt = response.data;
        }
      });
    },
  },
  setup() {
    const route = useRoute();
    const vm = getCurrentInstance();

    var transName = ref();
    var newUserCnt = ref('');
    var adrDataIFCnt = ref('');
    var mgDataIFCnt = ref('');
    const title = vm.proxy.$appInfo.title;
    const screen = reactive({ getScreenSizeInfo: {} });
    screen.getScreenSizeInfo = getScreenSizeInfo();

    function screenSizeChanged() {
      screen.getScreenSizeInfo = getScreenSizeInfo();
    }

    onMounted(() => {
      subscribe(screenSizeChanged);
    });

    onBeforeUnmount(() => {
      unsubscribe(screenSizeChanged);
    });

    const cssClasses = computed(() => {
      return ['app'].concat(screen.getScreenSizeInfo.cssClasses);
    });

    watch(route, (rt) => {
      transName.value = rt.meta.transName;
    });
    return {
      title,
      screen,
      cssClasses,
      transName,
      newUserCnt,
      adrDataIFCnt,
      mgDataIFCnt,
    };
  },
};
</script>

<style lang="scss">
html,
body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.app {
  @import './themes/generated/variables.base.scss';
  background-color: darken($base-bg, 5);
  display: flex;
  height: 100%;
  width: 100%;
}
</style>
