import store from './store';
import axios from 'axios';
import fn from './utils/function'

export default {

  async login(user) {
    let rtnValue = false;

    await axios.post('/loginProc',
      '',
      {
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        params: user
      }
    ).then(
      res => {
        let data = res.data.data;
        let result = res.data.result;

        if (result == "SUCCESS") {
          store.commit('setToken', data.token);
          store.commit('setUserId', data.userId);
          store.commit('setUserName', data.userName);
          store.commit('setAuth', data.auth);
          store.commit('setHospitalCode', data.hospitalCode);
          store.commit('setHospitalName', data.hospitalName);
          store.commit('setJob', data.job);
          store.commit('setEmail', data.email);
          store.commit('setTel', data.tel);
          store.commit('setMenuList', JSON.stringify(data.menuList));
          rtnValue = true;
        }
        else {
          fn.notifyError(data);
        }
      }).catch(
        error => {
          fn.notifyError(error);
        }
      );

    return rtnValue;
  },
  async changePassword(userInfo) {
    let rtnValue = false;

    let userId = store.getters.getUserId;
    let token = store.getters.getToken;
    let params = {
      userId: userId,
      dmlUserId: userId,
      newPassword: userInfo.new,
      userPassword: userInfo.current
    };

    await axios.post('/api/changePassword',
      '',
      {
        headers: { 'Content-Type': 'application/json; charset=UTF-8', 'userId': userId, 'token': token, to: 'change-password' },
        params: params
      }
    ).then(
      res => {
        let data = res.data.data;
        let result = res.data.result;

        if (result == "SUCCESS") {
          rtnValue = true;
          fn.notifySuccess("비밀번호 변경 성공");
        }
        else {
          rtnValue = false;
          fn.notifyError(data);
        }
      }).catch(
        error => {
          fn.notifyError(error);
        }
      );

    return rtnValue;
  },
  async loggedIn(to, updateExpireTime) {
    let rtnValue = '';
    let token = store.getters.getToken;
    let params = {
      token: token
      , userId: store.getters.getUserId
      , to: to
      , updateExpireTime: updateExpireTime
    }

    if (token) {
      await axios.post('/loginCheck',
        '',
        {
          headers: { 'Content-Type': 'application/json; charset=UTF-8' },
          params: params
        }
      ).then(
        res => {
          let result = res.data.result;
          let menuList = res.data.data;
          rtnValue = result;
          if (result != "SUCCESS" &&
            result != "NEED_CHANGE_PASSWORD" &&
            result != "VERIFY_EMAIL" &&
            result != "OTP_CONFIRM" &&
            result != "WAIT_CONFIRM" &&
            result != "NO_AUTH" &&
            result != "CHECK_OTP") {
            this.logOut();
          } else {
            let menuListOrg = store.getters.getMenuList;
            let menuListNew = JSON.stringify(menuList);
            if (menuListOrg != 'null' && menuListNew != 'null') {
              if (menuListOrg != menuListNew) {
                alert("관리자가 사용자 권한을 변경하여 새로고침 후 HOME 으로 이동합니다.");
                store.commit('setMenuList', menuListNew);
                location.reload();
                window.location.href = '/home';
              }
            }
          }
        }).catch(
          () => {
            this.logOut();
            rtnValue = false;
          }
        );

      return rtnValue;
    }
  },
  async logOut() {
    await axios.post('/logout',
      '',
      {
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        params: { token: store.getters.getToken }
      }
    ).then(
      res => {
        let result = res.data.result;
        if (result == "SUCCESS") {
          store.commit('setToken', '');
          store.commit('setUserId', '');
          store.commit('setUserName', '');
          store.commit('setAuth', '');
          store.commit('setHospitalCode', '');
          location.reload();
        } else {
          fn.notifyError(res.data.msg);
        }
      }).catch(
        error => {
          fn.notifyError(error);
        }
      );
  },
  async getUser() {
    try {
      return {
        isOk: true,
        data: {
          userId: store.getters.getUserId,
          userName: store.getters.getUserName
        }
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },
  async createAccount(userIno) {
    var rtnValue = '';
    await axios.post('/api/createAccount',
      '',
      {
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        params: userIno
      }
    ).then(
      res => {
        let result = res.data.result;
        let msg = res.data.msg;
        rtnValue = result;
        if (result == 'SUCCESS') {
          fn.notifySuccess(msg);
        } else {
          fn.notifyError(msg);
        }

      }).catch(
        error => {
          fn.notifyError(error);
          rtnValue = error;
        }
      );

    return rtnValue;
  },
  async verifyEmail(params) {
    var rtnValue = '';
    params.userId = store.getters.getUserId;
    await axios.post('/api/verifyEmail',
      '',
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'userId': store.getters.getUserId,
          'token': store.getters.getToken
        },
        params: params
      }
    ).then(
      res => {
        let result = res.data.result;
        let msg = res.data.msg;
        rtnValue = result;
        if (result == 'SUCCESS') {
          fn.notifySuccess(msg);
        } else {
          fn.notifyError(msg);
        }

      }).catch(
        error => {
          fn.notifyError(error);
          rtnValue = error;
        }
      );

    return rtnValue;
  },
  async verifyOtp(params) {
    var rtnValue = '';
    params.userId = store.getters.getUserId;
    await axios.post('/api/verifyOtp',
      '',
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'userId': store.getters.getUserId,
          'token': store.getters.getToken
        },
        params: params
      }
    ).then(
      res => {
        let result = res.data.result;
        let msg = res.data.msg;
        rtnValue = result;
        if (result == 'SUCCESS') {
          fn.notifySuccess(msg);
        } else {
          fn.notifyError(msg);
        }

      }).catch(
        error => {
          fn.notifyError(error);
          rtnValue = error;
        }
      );

    return rtnValue;
  },
  async checkOtp(params) {
    var rtnValue = '';
    params.userId = store.getters.getUserId;
    params.token = store.getters.getToken;
    await axios.post('/api/checkOtp',
      '',
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'userId': store.getters.getUserId,
          'token': store.getters.getToken
        },
        params: params
      }
    ).then(
      res => {
        let result = res.data.result;
        let msg = res.data.msg;
        rtnValue = result;
        if (result != 'SUCCESS') {
          fn.notifyError(msg);
        }

      }).catch(
        error => {
          fn.notifyError(error);
          rtnValue = error;
        }
      );

    return rtnValue;
  },
  async findId(params) {
    var rtnValue = '';
    params.userId = store.getters.getUserId;
    await axios.post('/api/findId',
      '',
      {
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        params: params
      }
    ).then(
      res => {
        let result = res.data.result;
        let data = res.data.data;
        let msg = res.data.msg;
        rtnValue = result;
        if (result == 'SUCCESS') {

          var popupString = "";

          if (data.length) {
            for (let user of data) {
              popupString += user.userId + '<br>';
            }
          } else {
            popupString = "일치하는 정보가 없습니다.";
          }

          fn.alert(popupString, "ID 검색 결과");
        } else {
          fn.notifyError(msg);
        }
      }).catch(
        error => {
          fn.notifyError(error);
          rtnValue = error;
        }
      );

    return rtnValue;
  },
  async findPassword(params) {
    var rtnValue = '';
    await axios.post('/api/findPassword',
      '',
      {
        headers: { 'Content-Type': 'application/json; charset=UTF-8' },
        params: params
      }
    ).then(
      res => {
        let result = res.data.result;
        let msg = res.data.msg;
        rtnValue = result;
        if (result == 'SUCCESS') {
          fn.alert(msg);
        } else {
          fn.notifyError(msg);
        }
      }).catch(
        error => {
          fn.notifyError(error);
          rtnValue = error;
        }
      );

    return rtnValue;
  },
  async reSendEC() {
    var rtnValue = '';
    await axios.post('/api/reSendEC',
      '',
      {
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'userId': store.getters.getUserId,
          'token': store.getters.getToken
        },
        params: { 'id': store.getters.getUserId }
      }
    ).then(
      res => {
        let result = res.data.result;
        let msg = res.data.msg;
        rtnValue = result;
        if (result == 'SUCCESS') {
          fn.alert(msg);
        } else {
          fn.notifyError(msg);
        }
      }).catch(
        error => {
          fn.notifyError(error);
          rtnValue = error;
        }
      );

    return rtnValue;
  }

};
