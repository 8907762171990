import store from './store';

export default {

  getMenuItems(newUserCnt, adrDataIFCnt, mgDataIFCnt) {
    const menuList = JSON.parse(store.getters.getMenuList);
    let prvGroupSeq = 0;
    let menuItem = [
      {
        text: "Short Cut",
        icon: "home",
        items: [{
          text: "HOME",
          path: "/home"
        }
        ]
      }
    ];

    for (var i = 0; i < menuList.length; i++) {
      if (prvGroupSeq != menuList[i].groupSeq) {
        prvGroupSeq = menuList[i].groupSeq;
        var tmpMenuSubItem = menuList.filter((item) => (item.groupSeq == menuList[i].groupSeq));
        var menuSubItem = [];
        for (var j = 0; j < tmpMenuSubItem.length; j++) {
          if (tmpMenuSubItem[j].transaction == 'AUSR' && newUserCnt) {
            menuSubItem.push({ text: tmpMenuSubItem[j].menuName + " (" + newUserCnt + ")", path: tmpMenuSubItem[j].path });
          } else if (tmpMenuSubItem[j].transaction == 'MRCV' && adrDataIFCnt) {
            menuSubItem.push({ text: tmpMenuSubItem[j].menuName + " (" + adrDataIFCnt + ")", path: tmpMenuSubItem[j].path });
          } else if (tmpMenuSubItem[j].transaction == 'MMMG' && mgDataIFCnt) {
            menuSubItem.push({ text: tmpMenuSubItem[j].menuName + " (" + mgDataIFCnt + ")", path: tmpMenuSubItem[j].path });
          } else {
            menuSubItem.push({ text: tmpMenuSubItem[j].menuName, path: tmpMenuSubItem[j].path });
          }
        }

        menuItem.push({
          text: menuList[i].groupName,
          icon: menuList[i].icon,
          items: menuSubItem
        });
      }
    }
    return menuItem;
  }
}