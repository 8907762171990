<template>
  <div class="content-block dx-card responsive-paddings">
      <DxToolbar>
        <DxItem :options="dateBoxOptionsStart"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>        
        <DxItem :options="dateBoxOptionsEnd"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>   
        <DxItem :options="buttonOptionsSearch"
                location="center"
                locate-in-menu="never"
                widget="dxButton"/>                    
      </DxToolbar>
  </div>
  
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      key-expr="recId"
      :focused-row-enabled="true"
      @exporting="onExporting($event)">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="virtual"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true" />

      <DxColumn caption="시스템 정보">
        <DxColumn
            data-field="recId"
            caption="Record ID"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="hospitalNameCode"
            caption="요양기관"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
      </DxColumn>
      
      <DxColumn caption="등록 희망자">
        <DxColumn
            data-field="ptName"
            caption="성명"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptNumber"
            caption="병원등록번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptRrn"
            caption="생년월일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptTel"
            caption="휴대폰 번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptEmail"
            caption="전자메일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptAddress"
            caption="주소"
            :header-filter="{ allowSearch: true }"
            width="200"
            cell-template="text-area-template">
        </DxColumn>
      </DxColumn>

      <DxColumn caption="신청인">
        <DxColumn
            data-field="apName"
            caption="성명"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
        
        <DxColumn
            data-field="apRelationship"
            caption="관계"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="apRrn"
            caption="생년월일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="apTel"
            caption="휴대폰 번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="apEmail"
            caption="전자메일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
      </DxColumn>

      <DxColumn
          data-field="d1List"
          caption="약물이상사례(개별사례)"
          :header-filter="{ allowSearch: false }"
          cell-template="d1-cell-template">
      </DxColumn>

      <DxColumn caption="약물이상사례(통합조정)">
        <DxColumn
            data-field="d1ManagementNumber"
            caption="원내_이상사례_관리번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2CurrentStatus"
            caption="현재상태"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
        
        <DxColumn
            data-field="d2Symptom"
            caption="증상"
            :header-filter="{ allowSearch: true }"
            cell-template="symptom-cell-template">
        </DxColumn>

        <DxColumn
            data-field="d2CiGroup"
            caption="금기 성분(군)"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>        

        <DxColumn
            data-field="d2DrugSideEffectsType"
            caption="유형"
            minWidth="200"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2CrhiGroup"
            caption="주의 성분(군)"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>                

        <DxColumn
            data-field="d2Causality"
            caption="인과성"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2OaiGroup"
            caption="복용가능 성분(군)"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>  

        <DxColumn
            data-field="d2Severity"
            caption="중증도"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2ConfTest"
            caption="확진검사"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2ExpertOpinion"
            caption="전문가의견"
            :header-filter="{ allowSearch: true }"
            width="300"
            cell-template="text-area-template">
        </DxColumn>
      </DxColumn>

      <DxColumn caption="의료기관">
        <DxColumn
            data-field="d3HospitalName"
            caption="의료기관명"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d3HospitalCode"
            caption="요양기관등록번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d3FinalEvaluator"
            caption="최종평가자 직역"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d3MedicalDepartment"
            caption="최종평가자 전문과목"
            minWidth="130"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d3EvaluationDepartment"
            caption="평가부서"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d3ManagerTel"
            caption="평가부서 전화번호"
            minWidth="200"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d3Email"
            caption="평가부서 Email"
            minWidth="200"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
      </DxColumn>
      
      <DxColumn caption="기타">
        <DxColumn
            data-field="d4InsertTime"
            caption="최초작성일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d4UpdateTime"
            caption="서식수정일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="status"
            caption="상태"
            :header-filter="{ allowSearch: true }">
            <DxLookup :data-source="recStatus"
                    value-expr="ID"
                    display-expr="Name"/>    
        </DxColumn>    
        
        <DxColumn
            data-field="printedCard"
            caption="약물카드 발급"
            :header-filter="{ allowSearch: true }">
        </DxColumn>    

        <DxColumn
            data-field="sentCard"
            caption="약물카드 전송"
            :header-filter="{ allowSearch: true }">
        </DxColumn>  
      </DxColumn>
      
      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"/>
        </template>  

        <template #d1-cell-template="{data}">
            <AdrDataD1 :d1ListData="data.value"/>
        </template>        

        <template #symptom-cell-template="{data}">
            <GridSymptom :symptomData="data.value"/>
        </template>        
        
        <template #drug-cell-template="{data}">
            <GridDrug :drugData="data.value"/>
        </template>    
        
        <template #text-area-template="{data}">
          <TextAreaTemplate :text="data.value"/>
        </template>              
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
         DxColumn,
         DxScrolling,
         DxSorting,
         DxHeaderFilter,
         DxSearchPanel,
         DxLookup,
         DxExport,
         DxMasterDetail} from "devextreme-vue/data-grid";
  import AdrDataD1 from "../_common/ADR_DATA_D1";          
  import History from "../_common/ADR_DATA_HISTORY";          
  import GridSymptom from "../_common/GRID_SYMPTOM";
  import GridDrug from "../_common/GRID_DRUG";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {userStatus} from "../../utils/syscodedata";
  import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
  import store from "../../store";
  import XLSX from 'sheetjs-style'; 
  import TextAreaTemplate from "../_common/TEXT_AREA_TMPLATE";

  var endDate = new Date();
  var startDate = new Date();
  startDate.setDate(endDate.getDate() - 7);
  var defaultStartDate = startDate;
  var defaultEndDate = endDate;

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      DxMasterDetail,
      DxLookup,
      History,
      GridSymptom,
      GridDrug,
      DxToolbar,
      DxItem,
      DxExport,
      AdrDataD1,
      TextAreaTemplate
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: [],
        recStatus: userStatus,
        searchCondition: {startDate: defaultStartDate, endDate: defaultEndDate},
        dateBoxOptionsStart: {
          width: 190,
          value: defaultStartDate,
          text: "시작",
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 190,
          value: defaultEndDate,
          text: "시작",
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }               
      }
    },
    computed: {
      grid: function () {
        return this.$refs[this.gridRefName].instance;
      }
    },
    methods: {
      onExporting(e) {
        e.cancel = true;
        const fieldsOrder = [
            "recId",
            "hospitalNameCode",
            "ptName",
            "ptNumber",
            "ptRrn",
            "ptTel",
            "ptEmail",
            "ptAddress",
            "apName",
            "apRelationship",
            "apRrn",
            "apTel",
            "apEmail",
            "d1OccurenceDate",
            "d1Causality",
            "d1Severity",
            "d1Symptom",
            "d1CausativeDrug",
            "d1CaseDescription",
            "d1ManagementNumber",
            "d2CurrentStatus",
            "d2Symptom",
            "d2CiGroup",
            "d2DrugSideEffectsType",
            "d2CrhiGroup",
            "d2Causality",
            "d2OaiGroup",
            "d2Severity",
            "d2ExpertOpinion",
            "d3HospitalName",
            "d3HospitalCode",
            "d3FinalEvaluator",
            "d3MedicalDepartment",
            "d3EvaluationDepartment",
            "d3ManagerTel",
            "d3Email",
            "d4InsertTime",
            "d4UpdateTime",
            "status",
            "printedCard",
            "sentCard"
        ];

        const headers = [
          [
            '시스템 정보',
            '',
            '등록 희망자',
            '',
            '',
            '',
            '',
            '',
            '신청인',
            '',
            '',
            '',
            '',
            '약물이상사례(개별사례)',
            '',
            '',
            '',
            '',
            '',
            '약물이상사례(통합조정)',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '',
            '의료기관',
            '',
            '',
            '',
            '',
            '',
            '',
            '기타',
            '',
            '',
            '',
            ''
          ], [
            'Record ID',
            '요양기관',
            '성명',
            '병원등록번호',
            '생년월일',
            '휴대폰 번호',
            '전자메일',
            '주소',
            '성명',
            '관계',
            '생년월일',
            '휴대폰 번호',
            '전자메일',
            '발생일',
            '인과성',
            '중증도',
            '증상',
            '원인약물',
            '사례_설명',
            '원내_이상사례_관리번호',
            '현재상태',
            '증상',
            '금기 성분(군)',
            '유형',
            '주의 성분(군)',
            '인과성',
            '복용가능 성분(군)',
            '중증도',
            '전문가의견',
            '의료기관명',
            '요양기관등록번호',
            '최종평가자 직역',
            '최종평가자 전문과목',
            '평가부서',
            '평가부서 전화번호',
            '평가부서 Email',
            '최초작성일',
            '서식수정일',
            '상태',
            '약물카드 발급',
            '약물카드 전송'
          ]
        ]
        dsu.getAxiosOBJGet('/api/MRPM/selectADRDataForDownload', this.searchParam).then(res=>{
          this.grid.endCustomLoading();
          let response = res.data;
          if (response.result == 'SUCCESS') {
              const modifiedData = response.data.map(row => {
              row.d1CausativeDrug = JSON.parse(row.d1CausativeDrug).map(item => `${item.drugType}, ${item.drugCode}, ${item.drugName}`).join("\n");
              row.d1Symptom = JSON.parse(row.d1Symptom).map(item => `${item.symptomCode}, ${item.symptomNameKor}, ${item.symptomNameEng}`).join("\n");
              if (row.d2CiGroup) {
                row.d2CiGroup = JSON.parse(row.d2CiGroup).map(item => `${item.drugType}, ${item.drugCode}, ${item.drugName}`).join("\n");
              }
              if(row.d2CrhiGroup){
                row.d2CrhiGroup = JSON.parse(row.d2CrhiGroup).map(item => `${item.drugType}, ${item.drugCode}, ${item.drugName}`).join("\n");
              }
              if (row.d2OaiGroup) {
                row.d2OaiGroup = JSON.parse(row.d2OaiGroup).map(item => `${item.d2OaiGroup}, ${item.d2OaiGroup}, ${item.d2OaiGroup}`).join("\n");
              }
              if(row.d2Symptom){
                row.d2Symptom = JSON.parse(row.d2Symptom).map(item => `${item.symptomCode}, ${item.symptomNameKor}, ${item.symptomNameEng}`).join("\n");
              }
              const reorderedRow = {};
              fieldsOrder.forEach(field => {
                reorderedRow[field] = row[field];
              });

              return reorderedRow;
            });
            const wb = XLSX.utils.book_new();
            const ws = XLSX.utils.json_to_sheet(modifiedData);
            XLSX.utils.sheet_add_aoa(ws, headers, { origin: 'A1' });

            // 헤더 아래 테두리 아래 선
            const headerRange = { s: { r: 0, c: 0 }, e: { r: 1, c: fieldsOrder.length - 1 } };
            for (let C = headerRange.s.c; C <= headerRange.e.c; ++C) {
              let cellRef = XLSX.utils.encode_cell({ r: 0, c: C });
              if (!ws[cellRef]) ws[cellRef] = { t: 's', v: '' };
              if (!ws[cellRef].s) ws[cellRef].s = {};
              ws[cellRef].s.font = {
                bold: true
              };

              let cellRef2 = XLSX.utils.encode_cell({ r: 1, c: C });
              if (!ws[cellRef2]) ws[cellRef2] = { t: 's', v: '' };
              if (!ws[cellRef2].s) ws[cellRef2].s = {};
              ws[cellRef2].s.border = {
                bottom: { style: 'thin', color: { rgb: '000000' } }
              };
              ws[cellRef2].s.font = {
                bold: true
              };
            }

            // 데이터 셀 스타일 설정 (세로 정렬을 가운데로, 테두리 추가)
            const dataRange = XLSX.utils.decode_range(ws['!ref']);
            for (let R = dataRange.s.r + 1; R <= dataRange.e.r; ++R) {
              for (let C = dataRange.s.c; C <= dataRange.e.c; ++C) {
                const cellAddress = { r: R, c: C };
                const cellRef = XLSX.utils.encode_cell(cellAddress);
                if (!ws[cellRef]) ws[cellRef] = { t: 's', v: '' };
                if (!ws[cellRef].s) ws[cellRef].s = {};
                
                ws[cellRef].s.alignment = { vertical: 'center' };
              }
            }

            ws['!merges'] = [
              { s: { r: 0, c: 0 }, e: { r: 0, c: 1 } },  // 시스템 정보
              { s: { r: 0, c: 2 }, e: { r: 0, c: 7 } },  // 등록 희망자
              { s: { r: 0, c: 8 }, e: { r: 0, c: 12 } }, // 신청인
              { s: { r: 0, c: 13 }, e: { r: 0, c: 18 } }, // 약물이상사례(개별사례)
              { s: { r: 0, c: 19 }, e: { r: 0, c: 28 } }, // 약물이상사례(통합조정)
              { s: { r: 0, c: 29 }, e: { r: 0, c: 35 } }, // 의료기관
              { s: { r: 0, c: 36 }, e: { r: 0, c: 40 } }  // 기타
            ];

            const mergeRanges = this.getMergeRanges(modifiedData, fieldsOrder, 'recId', [
              'recId',
              'hospitalNameCode',
              'ptName',
              'ptNumber',
              'ptRrn',
              'ptTel',
              'ptEmail',
              'ptAddress',
              'apName',
              'apRelationship',
              'apRrn',
              'apTel',
              'apEmail',
              'd2CurrentStatus',
              'd2Symptom',
              'd2CiGroup',
              'd2DrugSideEffectsType',
              'd2CrhiGroup',
              'd2Causality',
              'd2OaiGroup',
              'd2Severity',
              'd2ExpertOpinion',
              'd3HospitalName',
              'd3HospitalCode',
              'd3FinalEvaluator',
              'd3MedicalDepartment',
              'd3EvaluationDepartment',
              'd3ManagerTel',
              'd3Email',
              'd4InsertTime',
              'd4UpdateTime',
              'status',
              'printedCard',
              'sentCard'
            ]);
            ws['!merges'] = ws['!merges'].concat(mergeRanges);
            const colWidths = fieldsOrder.map(field => {
            let maxLength = headers[1][fieldsOrder.indexOf(field)].length;
            modifiedData.forEach(row => {
              const cellValue = row[field] ? row[field].toString() : '';
              if (cellValue.length > maxLength) {
                maxLength = cellValue.length;
              }
            });
            return { wch: maxLength + 2 }; // padding for better fit
          });

          ws['!cols'] = colWidths;

            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, '부작용 정보 조회.xlsx');
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      getMergeRanges(data, fieldsOrder, mergeColumn, mergeFields) {
        const mergeRanges = [];
        let startRow = 1; // 데이터가 2번째 행부터 시작하므로 (0-based index)

        for (let i = 1; i < data.length; i++) {
          if (data[i][mergeColumn] !== data[startRow][mergeColumn]) {
            mergeFields.forEach(field => {
              const colIndex = fieldsOrder.indexOf(field);
              if (colIndex !== -1) {
                mergeRanges.push({ s: { r: startRow + 1, c: colIndex }, e: { r: i, c: colIndex } });
              }
            });
            startRow = i;
          }
        }

        // 마지막 그룹 처리
        if (startRow < data.length - 1) {
          mergeFields.forEach(field => {
            const colIndex = fieldsOrder.indexOf(field);
            if (colIndex !== -1) {
              mergeRanges.push({ s: { r: startRow + 1, c: colIndex }, e: { r: data.length, c: colIndex } });
            }
          });
        }
        return mergeRanges;
      },
      search(){
        this.searchParam = this.getSearchParam();
        this.grid.beginCustomLoading("데이터 조회 중...");
        dsu.getAxiosOBJGet('/api/MRPM/selectADRData', this.searchParam).then(res=>{
          this.grid.endCustomLoading();
          let response = res.data;
          if (response.result == 'SUCCESS') {
            this.dataSrouceRaw = response.data;
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      getSearchParam() {
        var params = JSON.parse(JSON.stringify(this.searchCondition));
        params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
        params.endDate = dsu.getFormatDate(this.searchCondition.endDate);

        const menuAuth = JSON.parse(store.getters.getMenuList).find(
          (item) => item.transaction === "MRPM"
        );

        if (menuAuth.f1Auth == "true") {
          params.searchAll = "Y";
        } else {
          params.searchAll = "N";
        }
        return params;    
      },
      setGridData(data)
      {
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('keyExpr', 'recId');
        grid.option('dataSource', data);
      }
    }
  };
</script>

<style scoped>
  #grid{
    max-height: calc(100vh - 330px);
  }
</style>