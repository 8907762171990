import {
    alert,
    confirm
} from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import ds from '../utils/dataSourceUtils';
import axios from 'axios';
export default {
    notifySuccess(msg) {
        notify({ message: msg, position: 'top' }, "success", 2000);
    },
    notifyError(msg) {
        notify({ message: msg, position: 'top' }, "error", 2500);
    },
    alert(msg, title) {
        if (title) {
            return alert(msg, title);
        } else {
            return alert(msg, '결과');
        }
    },
    confirm(msg) {
        return confirm(msg, '확인');
    },
    downLoadFile(e) {
        var columnName = e.column.dataField;
        if (columnName) {
            if (columnName.toUpperCase().includes('FILE') && e.value) {
                var filePath = e.data[columnName + 'Path'];
                window.location.href = '/fileDownload?file=' + encodeURIComponent(filePath);
            }
        }
    },
    getGridSubject(subject) {
        return "▣ " + subject;
    },
    parseCode(argNameCode) {
        var code = argNameCode.split('(')[1].split(')')[0];
        return code;
    },
    convSearchString(orgStr) {
        return orgStr.toUpperCase().replaceAll(" ", "");
    },
    async downLoadFileFromId(fileId) {
        if (fileId == null || fileId == "") {

            return;
        }
        try {
            var params = {};
            ds.setDefaultParams(params);
            params.fileId = fileId;
            const response = await axios({
                url: '/fileDownloadFromId', // 파일 URL을 여기에 넣으세요
                method: 'GET',
                params: params,
                headers: ds.getHeaders(),
                responseType: 'blob', // 파일 데이터를 Blob 형식으로 받음
            });
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = decodeURIComponent(response.headers['filename']); // 다운로드할 파일명을 여기에 넣으세요
            link.click();
        } catch (e) {
            this.notifyError('파일 다운로드 실패');
        }
    },
}