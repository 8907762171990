<template>
    <DxTextBox
    :value="url"
    styling-mode="filled">
        <DxTextBoxButton
            name="copy"
            :options="{icon: 'copy',
                        text: 'URL 복사',
                        onClick: copyClipBoard}"
            location="after"/>
        <DxTextBoxButton
        name="qrCode"
            :options="{icon: 'copy',
                        text: 'QR 코드 보기',
                        onClick: viewQrCode}"
            location="after"/>
    </DxTextBox>

    <DxPopup :ref="popupRefName"
        height="350px"
        width="300px"
        :drag-enabled="false"
        :hideOnOutsideClick="true"
        :showTitle="true"
        title="QR Code">    
        <div class="qrCodeContainer">
            <VueQRCodeComponent 
                ref="qrcode"
                :text="url"
                size="150"/>
            <DxButton
                class="btnDownload"
                :width="150"
                text="다운로드"
                type="normal"
                icon="download"
                styling-mode="contained"
                @click="downnloadQrCode($event)"/>
        </div>
      </DxPopup>
</template>

<script>
    import {DxTextBox, 
            DxButton as DxTextBoxButton } from 'devextreme-vue/text-box';
    import DxPopup from "devextreme-vue/popup";   
    import DxButton from 'devextreme-vue/button';
    import VueQRCodeComponent from 'vue-qrcode-component';     
    import dsu from "../../utils/dataSourceUtils";
    import fn from "../../utils/function";

    export default {
        components: {
            DxTextBox,
            DxTextBoxButton,
            DxButton,
            DxPopup,
            VueQRCodeComponent
        },
        data() {
            return {
                url: '',
                popupRefName: 'popup',
                
            }
        },    
        props: {
            recId: String,
            recordData: Object,
            ptInfo: Object
        },
        mounted(){
            dsu.getAxiosOBJGet('/api/MVUR/selectPTURL', {recId: this.recId}).then(res=>{
                let response = res.data;
                if(response.result == 'SUCCESS'){
                    this.url = 'https://myadr.care/p?c=' + response.data[0].url;
                }else{
                    fn.notifyError(response.msg);
                }
            });
        },
        methods:{
            copyClipBoard(){
                window.navigator.clipboard.writeText(this.url).then(() => {
                    fn.notifySuccess("복사 완료");
                });
            },
            viewQrCode(){
                this.$refs[this.popupRefName].instance.show();
            },
            downnloadQrCode() {
                const qrcodeCanvas = this.$refs.qrcode.$el.querySelector("canvas");
                const dataUrl = qrcodeCanvas.toDataURL("image/png");
                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = "MyADR_QR_Code_" + this.recordData.hospitalNameCode + "_" + this.ptInfo.ptName + "(" + this.recordData.ptNumber +  ").png";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };
     
</script>

<style lang="scss">
    .qrCodeContainer{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
         .btnDownload{
            margin-top: 20px;
         }   
    }
</style>