<template>
  <DxDataGrid
      :showColumnHeaders="false"
      :data-source="targetList"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true">
      <DxScrolling mode="infinite"/>
      <DxSelection mode="none"/>
      <DxColumn data-field="symptom"
                cell-template="symptom-cell-template"/>
        <template #symptom-cell-template="{ data }">
          <div class="symptomCode">{{data.data.symptomCode}}</div>
          <div class="symptomName">{{data.data.symptomNameKor}}</div>
          <div class="symptomName">{{data.data.symptomNameEng}}</div>
        </template>   
    </DxDataGrid>
</template>
<script>

import {DxDataGrid,
      DxSelection,
      DxScrolling,
      DxColumn
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn
  },
  props: {
    symptomData: String
  },
  mounted(){
    this.targetList = JSON.parse(this.symptomData);
  },
  data() {
    return {
      targetList: []
    };
  }
};
</script>

<style scoped>
.symptomCode{
  font-weight: 1000;
}
.symptomName{
  padding-left: 5px;
}
</style>