<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm :ref="formTLLRefName" label-location="left" :formData="formTLLData">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />
      <DxSimpleItem
        data-field="templateId"
        editor-type="dxSelectBox"
        :editor-options="{
          items: templateList,
          searchEnabled: true,
          displayExpr: 'dspName',
          valueExpr: 'templateId',
          onValueChanged: onValueChangedTemplateIdSearch,
        }"
      >
        <DxLabel text="Template" />
      </DxSimpleItem>
    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
    <DxForm :ref="formTLDRefName" label-location="top" :formData="formTLDData">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />
      <DxSimpleItem
        data-field="templateId"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          inputAttr: { style: 'text-transform: uppercase' },
          onValueChanged: onValueChangedTemplateId,
        }"
      >
        <DxLabel text="Template ID" />
        <DxRequiredRule />
        <DxPatternRule
          pattern="^[A-Z0-9]{1,20}$"
          message="영문 대문자 숫자 로만 구성, 20자 까지 등록 가능"
        />
      </DxSimpleItem>
      <DxSimpleItem data-field="templateTitle" editor-type="dxTextBox">
        <DxLabel text="Title" />
        <DxRequiredRule />
        <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
      </DxSimpleItem>
      <DxSimpleItem data-field="d3FinalEvaluator" editor-type="dxTextBox">
        <DxLabel text="최종평가자 직역" />
        <DxRequiredRule />
        <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
      </DxSimpleItem>
      <DxSimpleItem data-field="d3MedicalDepartment" editor-type="dxTextBox">
        <DxLabel text="최종평가자 전문과목" />
        <DxRequiredRule />
        <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
      </DxSimpleItem>
      <DxSimpleItem data-field="d3EvaluationDepartment" editor-type="dxTextBox">
        <DxLabel text="평가부서" />
        <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
      </DxSimpleItem>
      <DxSimpleItem data-field="d3ManagerTel" editor-type="dxTextBox">
        <DxLabel text="평가부서 전화번호" />
        <DxPatternRule pattern="^.{1,20}$" message="20자 까지 등록 가능" />
      </DxSimpleItem>
      <dx-item
        data-field="d3Email"
        editor-type="dxTextBox"
        :editor-options="{ stylingMode: 'filled', mode: 'email' }">
        <dx-label text="평가부서 Email" />
        <dx-required-rule />
        <DxPatternRule pattern="^.{2,50}$" message="50자까지 입력 가능" />
        <dx-email-rule message="이메일 형식 오류" />
      </dx-item>
      <DxEmptyItem />
      <DxGroupItem :colCount="3">
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            text="신규"
            type="normal"
            @click="newTemplate"
          />
        </DxButtonItem>
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            type="success"
            template="saveTemplate"
            @click="save"
          />
        </DxButtonItem>
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            type="danger"
            template="removeTemplate"
            @click="remove"
          />
        </DxButtonItem>
      </DxGroupItem>

      <template #saveTemplate>
        <div>
          <span class="dx-button-text">
            <DxLoadIndicator v-if="loadingSave" width="24px" height="24px" />
            <span v-if="!loadingSave">저장</span>
          </span>
        </div>
      </template>
      <template #removeTemplate>
        <div>
          <span class="dx-button-text">
            <DxLoadIndicator v-if="loadingDelete" width="24px" height="24px" />
            <span v-if="!loadingRemove">삭제</span>
          </span>
        </div>
      </template>
    </DxForm>
  </div>
</template>

<script>
import DxForm, {
  DxColCountByScreen,
  DxLabel,
  DxSimpleItem,
  DxRequiredRule,
  DxPatternRule,
  DxGroupItem,
  DxEmptyItem,
  DxButtonItem,
  DxButtonOptions,
  DxItem,
  DxEmailRule
} from "devextreme-vue/form";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxLabel,
    DxSimpleItem,
    DxRequiredRule,
    DxPatternRule,
    DxGroupItem,
    DxEmptyItem,
    DxButtonItem,
    DxButtonOptions,
    DxLoadIndicator,
    DxItem,
    DxEmailRule
  },
  data() {
    return {
      formTLLRefName: "formTLL",
      formTLLData: { templateId: "" },
      templateList: [],
      formTLDRefName: "formTLD",
      formTLDData: {},
      loadingNew: false,
      loadingSave: false,
      loadingRemove: false,
      selectedTemplateId: "",
    };
  },
  mounted() {
    this.refreshTemplateList();
  },
  methods: {
    refreshTemplateList() {
      dsu.getAxiosD3TemplateInfo().then((res) => {
        this.templateList = res.data.data;
      });
    },
    loadD3TemplateInfo(templateId) {
      dsu.getAxiosD3TemplateInfo({ templateId: templateId }).then((res) => {
        this.formTLDData = res.data.data[0];
      });
    },
    setD2CiGroup(d2CiGroup) {
      this.formTLDData.d2CiGroup = JSON.stringify(d2CiGroup);
    },
    setD2CrhiGroup(d2CrhiGroup) {
      this.formTLDData.d2CrhiGroup = JSON.stringify(d2CrhiGroup);
    },
    setD2OaiGroup(d2OaiGroup) {
      this.formTLDData.d2OaiGroup = JSON.stringify(d2OaiGroup);
    },
    newTemplate() {
      location.reload();
    },
    save(e) {
      const TLDForm = this.$refs[this.formTLDRefName].instance;
      var params = JSON.parse(JSON.stringify(this.formTLDData));
      let validationResult = TLDForm.validate();

      if (validationResult.status != "valid") {
        fn.notifyError("입력 데이터 확인 필요");
        return;
      }
      this.loadingSave = true;
      e.component.option("disabled", true);
      dsu.getAxiosOBJPost("/api/MD3T/insert", params).then((res) => {
        let response = res.data;
        e.component.option("disabled", false);

        if (response.result == "SUCCESS") {
          fn.notifySuccess("저장 완료");
          this.refreshTemplateList();
        } else {
          fn.notifyError(response.msg);
        }
        this.loadingSave = false;
      });
    },
    async remove() {
      const TLDForm = this.$refs[this.formTLDRefName].instance;
      var params = {};
      params.templateId = this.formTLDData.templateId;

      if (!params.templateId) {
        fn.notifyError("Template ID 선택 후 진행필요");
        return;
      }

      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
        return;
      }

      this.loadingRemove = true;
      dsu.getAxiosOBJPost("/api/MD3T/delete", params).then((res) => {
        let response = res.data;
        if (response.result == "SUCCESS") {
          fn.notifySuccess("삭제 완료");
          this.refreshTemplateList();
          TLDForm.resetValues();
          this.formTLDData = {};
        } else {
          fn.notifyError(response.msg);
        }
        this.loadingRemove = false;
      });
    },
    onValueChangedTemplateId(e) {
      this.formTLDData.templateId = e.value.toUpperCase();
    },
    onValueChangedTemplateIdSearch(e) {
      this.loadD3TemplateInfo(e.value);
    },
  },
};
</script>

<style scoped></style>
