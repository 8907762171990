<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @toolbar-preparing="onToolbarPreparing"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />
      <DxEditing mode="popup"  
                :allow-adding="true"
                :allow-deleting="true">
        <DxTexts saveRowChanges="API Key 발급"/>
      </DxEditing>

      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="hospitalNameCode"
        caption="요양기관"
        edit-cell-template="dropDownBoxEditor"
      >
        <DxRequiredRule />
      </DxColumn>

      <DxColumn data-field="apiKey"
                caption="API Key" 
                :allowEditing="false"
                :editorOptions="{placeholder: '20자리 API Key 자동 생성 됨'}"/>

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <History
          :master-detail-data="data"
          :url="`/api/AMAK/selectHistory`"
          :key1="data.data.hospitalCode"
          :columns="historyColumns"
        />
      </template>

      <template #dropDownBoxEditor="{ data: cellInfo }">
        <DdSearchHospital
          :value="cellInfo.value"
          :on-value-changed="cellInfo.setValue"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxEditing,
  DxSearchPanel,
  DxRequiredRule,
  DxMasterDetail,
  DxTexts
} from "devextreme-vue/data-grid";
import History from "../_common/SIMPLE_HISTORY";
import DdSearchHospital from "../_common/DD_SEARCH_HOSPITAL";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import { job, userStatus } from "../../utils/syscodedata";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxEditing,
    DxSearchPanel,
    DxRequiredRule,
    DxMasterDetail,
    History,
    DdSearchHospital,
    DxTexts
  },
  data() {
    return {
      formData: { hospitalCode: "" },
      gridRefName: "grid",
      dataSource: dsu.getComDSGrid("/api/AMAK", "hospitalNameCode", "", "요양기관"),
      popupSrearchRefName: "popup",
      hospitalList: [],
      jobList: job,
      userStatusList: userStatus,
      menuAuth: [],
      historyColumns: [
        { dataField: "transTime", caption: "변경 시간" },
        { dataField: "userId", caption: "변경 사용자" },
        { dataField: "apiKey", caption: "API Key" },
        { dataField: "dml", caption: "Action" }        
      ],
    };
  },
  methods: {
    showHospitalSearch() {
      this.$refs[this.popupSrearchRefName].show();
    },
    onRowInserting(e) {
      e.data.hospitalCode = fn.parseCode(e.data.hospitalNameCode);
    },
    onRowUpdating(e) {
      if (e.newData.hospitalNameCode) {
        e.newData.hospitalCode = fn.parseCode(e.newData.hospitalNameCode);
      }
    },
    setHospitalData(hospitalOrg) {
      this.formData.hospitalCode = hospitalOrg;
    },
     onToolbarPreparing: function (e) {
      let toolbarItems = e.toolbarOptions.items;
      toolbarItems.forEach(function (item) {
        if (item.name === "addRowButton") {
          item.options.text = "API Key 발급";
          item.options.stylingMode = "outlined";
          item.showText = "always";
        }
      });  
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 210px);
}
</style>
