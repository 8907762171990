<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm :ref="formTLLRefName" label-location="left" :formData="formTLLData">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />
      <DxSimpleItem
        data-field="templateId"
        editor-type="dxSelectBox"
        :editor-options="{
          items: templateList,
          searchEnabled: true,
          displayExpr: 'dspName',
          valueExpr: 'templateId',
          onValueChanged: onValueChangedTemplateIdSearch,
        }"
      >
        <DxLabel text="Template" />
      </DxSimpleItem>
    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
    <DxForm :ref="formTLDRefName" label-location="top" :formData="formTLDData">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />
      <DxSimpleItem
        data-field="templateId"
        editor-type="dxTextBox"
        :editor-options="{
          stylingMode: 'filled',
          inputAttr: { style: 'text-transform: uppercase' },
          onValueChanged: onValueChangedTemplateId,
        }"
      >
        <DxLabel text="Template ID" />
        <DxRequiredRule />
        <DxPatternRule
          pattern="^[A-Z0-9]{1,20}$"
          message="영문 대문자 숫자 로만 구성, 20자 까지 등록 가능"
        />
      </DxSimpleItem>
      <DxSimpleItem data-field="templateTitle" editor-type="dxTextBox">
        <DxLabel text="Title" />
        <DxRequiredRule />
        <DxPatternRule pattern="^.{1,50}$" message="50자 까지 등록 가능" />
      </DxSimpleItem>
      <DxSimpleItem data-field="d2CiGroup">
        <template #default>
          <DxLabel text="금기 성분(군)" />
          <ModifyDrugAtc
            title="금기 성분(군)"
            :drugInputType="formTLDData.d2CiGroupInputType"
            :drugData="formTLDData.d2CiGroup"
            @setDrugInputType="setD2CiGroupInputType"
            @setDrugData="setD2CiGroup"
            @getDrugData="getD2CiGroup"
            :isTemplateMode="true"
          />
        </template>
      </DxSimpleItem>
      <DxSimpleItem data-field="d2CrhiGroup">
        <template #default>
          <DxLabel text="주의 성분(군)" />
          <ModifyDrugAtc
            title="주의 성분(군)"
            :drugInputType="formTLDData.d2CrhiGroupInputType"
            :drugData="formTLDData.d2CrhiGroup"
            @setDrugInputType="setD2CrhiGroupInputType"
            @setDrugData="setD2CrhiGroup"
            @getDrugData="getD2CrhiGroup"
            :isTemplateMode="true"
          />
        </template>
      </DxSimpleItem>
      <DxSimpleItem data-field="d2OaiGroup">
        <template #default>
          <DxLabel text="복용가능 성분(군)" />
          <ModifyDrugAtc
            title="복용가능 성분(군)"
            :drugInputType="formTLDData.d2OaiGroupInputType"
            :drugData="formTLDData.d2OaiGroup"
            @setDrugInputType="setD2OaiGroupInputType"
            @setDrugData="setD2OaiGroup"
            @getDrugData="getD2OaiGroup"
            :isTemplateMode="true"
          />
        </template>
      </DxSimpleItem>
      <DxSimpleItem
        data-field="d2ExpertOpinion"
        editor-type="dxTextArea"
        :editor-options="{ height: '200px' }"
      >
        <DxLabel text="전문가 의견" />
        <DxRequiredRule />
        <DxPatternRule
          pattern="^(.|\n|\r){1,1500}$"
          message="1500자 까지 등록 가능"
        />
      </DxSimpleItem>
      <DxEmptyItem />
      <DxGroupItem :colCount="3">
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            text="신규"
            type="normal"
            @click="newTemplate"
          />
        </DxButtonItem>
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            type="success"
            template="saveTemplate"
            @click="save"
          />
        </DxButtonItem>
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            type="danger"
            template="removeTemplate"
            @click="remove"
          />
        </DxButtonItem>
      </DxGroupItem>

      <template #saveTemplate>
        <div>
          <span class="dx-button-text">
            <DxLoadIndicator v-if="loadingSave" width="24px" height="24px" />
            <span v-if="!loadingSave">저장</span>
          </span>
        </div>
      </template>
      <template #removeTemplate>
        <div>
          <span class="dx-button-text">
            <DxLoadIndicator v-if="loadingDelete" width="24px" height="24px" />
            <span v-if="!loadingRemove">삭제</span>
          </span>
        </div>
      </template>
    </DxForm>
  </div>
</template>

<script>
import DxForm, {
  DxColCountByScreen,
  DxLabel,
  DxSimpleItem,
  DxRequiredRule,
  DxPatternRule,
  DxGroupItem,
  DxEmptyItem,
  DxButtonItem,
  DxButtonOptions,
} from "devextreme-vue/form";
import ModifyDrugAtc from "../_common/MODIFY_DRUG_ATC";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxLabel,
    DxSimpleItem,
    DxRequiredRule,
    DxPatternRule,
    ModifyDrugAtc,
    DxGroupItem,
    DxEmptyItem,
    DxButtonItem,
    DxButtonOptions,
    DxLoadIndicator,
  },
  data() {
    return {
      formTLLRefName: "formTLL",
      formTLLData: { templateId: "" },
      templateList: [],
      formTLDRefName: "formTLD",
      formTLDData: {},
      loadingSave: false,
      loadingRemove: false,
      selectedTemplateId: "",
    };
  },
  mounted() {
    this.refreshTemplateList();
  },
  methods: {
    refreshTemplateList() {
      dsu.getAxiosD2TemplateInfo().then((res) => {
        this.templateList = res.data.data;
      });
    },
    loadD2TemplateInfo(templateId) {
      dsu.getAxiosD2TemplateInfo({ templateId: templateId }).then((res) => {
        this.formTLDData = res.data.data[0];
      });
    },
    setD2CiGroup(d2CiGroup) {
      this.formTLDData.d2CiGroup = JSON.stringify(d2CiGroup);
    },
    getD2CiGroup(callback){
      if (this.formTLDData.d2CiGroup) {
        callback(this.formTLDData.d2CiGroup);
      }
    },
    setD2CrhiGroup(d2CrhiGroup) {
      this.formTLDData.d2CrhiGroup = JSON.stringify(d2CrhiGroup);
    },
    getD2CrhiGroup(callback){
      if (this.formTLDData.d2CrhiGroup) {
        callback(this.formTLDData.d2CrhiGroup);
      }
    },
    setD2OaiGroup(d2OaiGroup) {
      this.formTLDData.d2OaiGroup = JSON.stringify(d2OaiGroup);
    },
    getD2OaiGroup(callback){
      if (this.formTLDData.d2OaiGroup) {
        callback(this.formTLDData.d2OaiGroup);
      }
    },
    newTemplate() {
      location.reload();
    },
    save(e) {
      const TLDForm = this.$refs[this.formTLDRefName].instance;
      var params = JSON.parse(JSON.stringify(this.formTLDData));
      let validationResult = TLDForm.validate();

      if (validationResult.status != "valid") {
        fn.notifyError("입력 데이터 확인 필요");
        return;
      }

      e.component.option("disabled", true);
      this.loadingSave = true;
      dsu.getAxiosOBJPost("/api/MD2T/insert", params).then((res) => {
        let response = res.data;
        e.component.option("disabled", false);
        if (response.result == "SUCCESS") {
          fn.notifySuccess("저장 완료");
          this.refreshTemplateList();
        } else {
          fn.notifyError(response.msg);
        }
        this.loadingSave = false;
      });
    },
    async remove() {
      const TLDForm = this.$refs[this.formTLDRefName].instance;
      var params = {};
      params.templateId = this.formTLDData.templateId;

      if (!params.templateId) {
        fn.notifyError("Template ID 선택 후 진행필요");
        return;
      }

      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
        return;
      }

      this.loadingRemove = true;
      dsu.getAxiosOBJPost("/api/MD2T/delete", params).then((res) => {
        let response = res.data;
        if (response.result == "SUCCESS") {
          fn.notifySuccess("삭제 완료");
          this.refreshTemplateList();
          TLDForm.resetValues();
          this.formTLDData = {};
        } else {
          fn.notifyError(response.msg);
        }
        this.loadingRemove = false;
      });
    },
    onValueChangedTemplateId(e) {
      this.formTLDData.templateId = e.value.toUpperCase();
    },
    onValueChangedTemplateIdSearch(e) {
      this.loadD2TemplateInfo(e.value);
    },
    setD2CiGroupInputType(inputType) {
      this.formTLDData.d2CiGroupInputType = inputType;
    },
    setD2CrhiGroupInputType(inputType) {
      this.formTLDData.d2CrhiGroupInputType = inputType;
    },
    setD2OaiGroupInputType(inputType) {
      this.formTLDData.d2OaiGroupInputType = inputType;
    },
  },
};
</script>

<style scoped></style>
