<template>
  <DxTextBox
      :value="atcNameKorShort"
      :input-attr="{ 'aria-label': 'Name' }"
      maxLength="50"
      @valueChanged="onValueChangedTB">
  </DxTextBox>
  <div class="titleOthers">타 기관 사용 정보 추천(클릭 한 정보 자동 입력)</div>
  <DxDataGrid
        :ref="gridRefName"
        :data-source="dataSourceGrid"
        :columnAutoWidth="true"
        :show-borders="true"
        :show-column-lines="true"
        @rowClick="onClick">
        <DxScrolling mode="virtual"/>
        <DxSelection mode="single"/>
        <DxColumn
            data-field="hospitalNameCode"
            caption="요양기관"
            :header-filter="{ allowSearch: true }"/>
        <DxColumn
            data-field="atcNameKorShort"
            caption="ATC Short Name(KOR)"
            :header-filter="{ allowSearch: true }"/>
    </DxDataGrid>
</template>
<script>

import {
  DxDataGrid,
  DxSelection,
  DxScrolling,
  DxColumn
} from 'devextreme-vue/data-grid';
import DxTextBox from 'devextreme-vue/text-box';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';


export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn,
    DxTextBox
  },
  props: {
    atcInfo: Object,
    onValueChanged: {
      type: Function,
      default: () => function() {},
    },
  },
  data() {
    return {
      dataSourceGrid: [],
      atcNameKorShort: ''
    }
  },
  mounted() {
    this.atcNameKorShort = this.atcInfo.atcNameKorShort;
    var params = {};
    params.atcCode = this.atcInfo.atcCode;
      dsu.getAxiosOBJGet("/api/MATC/selectAtcInfoHospitalOthers", params).then((res) => {
        let rtnMsg = res.data.msg;
        if (res.data.result == "SUCCESS") {
          this.dataSourceGrid = res.data.data;
        } else {
          fn.notifyError(rtnMsg);
        }
      });    
  },
  methods: {
    onValueChangedTB(e) {
      this.onValueChanged(e.value);
    },
    onClick(e) {
     this.atcNameKorShort = e.data.atcNameKorShort;
    }
  }
};
</script>
<style lang="scss">
.titleOthers{
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 15px;
}
</style>


