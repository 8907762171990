<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      key-expr="atcCode"
      @row-updating="onRowUpdating"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />
      
      <DxEditing
        :allow-updating="true"
        :allow-adding="false"
        :allow-deleting="false"
        mode="popup"
      />

      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="atcCode"
        caption="ATC Code"
        :allowEditing="false"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="atcName"
        caption="ATC Name(ENG)"
        :allowEditing="false"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="atcNameKor"
        caption="ATC Name(KOR)"
        :allowEditing="false"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="atcNameKorShort"
        caption="ATC Short Name(KOR)"
        :header-filter="{ allowSearch: true }"
        edit-cell-template="recommend-template">
      </DxColumn>

      <template #recommend-template="{ data }">
        <RecShortName
          :atcInfo="data.data"
          :on-value-changed="data.setValue"
        />
      </template>    


      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/MATC/selectAtcInfoHospitalHst`"
                  :keyName="atcCode"
                  :columns="historyColumns"/>
        </template>    
    </DxDataGrid>

    <DxPopup :ref="popupRefName"
      height="90%"
      width="90%"
      :drag-enabled="false"
      :hideOnOutsideClick="true"
      :showTitle="true"
      title="타 기관 ATC Short Name 사용 정보">
        <DxDataGrid
          :ref="gridOthersRefName"
          :data-source="dataSourceOthers"
          :columnAutoWidth="true"
          :show-borders="true"
          :show-column-lines="true"
          height="100%"
          width="100%"
          :key-expr="['hospitalCode', 'atcCode']"
          @toolbar-preparing="onToolbarPreparingOthers($event)"
        >
          <DxSearchPanel :visible="true" />
          <DxHeaderFilter :visible="true" />
          <DxScrolling mode="virtual" />
          <DxSorting mode="multiple" />
          <DxSelection mode="single"/>
          <DxColumn
            data-field="hospitalCode"
            :visible="false">
          </DxColumn>

          <DxColumn
            data-field="hospitalNameCode"
            caption="요양기관"
            :allowEditing="false"
            :header-filter="{ allowSearch: true }">
          </DxColumn>

          <DxColumn
            data-field="atcCode"
            caption="ATC Code"
            :allowEditing="false"
            :header-filter="{ allowSearch: true }">
          </DxColumn>

          <DxColumn
            data-field="atcName"
            caption="ATC Name(ENG)"
            :allowEditing="false"
            :header-filter="{ allowSearch: true }">
          </DxColumn>

          <DxColumn
            data-field="atcNameKor"
            caption="ATC Name(KOR)"
            :allowEditing="false"
            :header-filter="{ allowSearch: true }">
          </DxColumn>

          <DxColumn
            data-field="atcNameKorShort"
            caption="ATC Short Name(KOR)"
            :header-filter="{ allowSearch: true }">
          </DxColumn>
        </DxDataGrid>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxEditing,
  DxSearchPanel,
  DxMasterDetail,
  DxSelection
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";        
import History from "../_common/SIMPLE_HISTORY";
import RecShortName from "../_common/REC_SHORT_NAME";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxEditing,
    DxSearchPanel,
    DxMasterDetail,
    DxPopup,
    History,
    DxSelection,
    RecShortName
  },
  data() {
    return {
      gridRefName: "grid",
      dataSource: [],
      dataSourceOthers: [],
      historyColumns: [
        { dataField: "transTime", caption: "변경 시간" },
        { dataField: "atcNameKorShort", caption: "ATC Short Name(KOR)" },
        { dataField: "userId", caption: "변경 사용자" },
        { dataField: "dml", caption: "Action" }
      ],
      popupRefName: "popup",
      gridOthersRefName: "gridOthers",
    };
  },
  computed: {
    grid: function () { return this.$refs[this.gridRefName].instance;},
    gridOthers: function () { return this.$refs[this.gridOthersRefName].instance; },
    popup: function () { return this.$refs[this.popupRefName].instance;}
  },
  mounted() {
    this.refreshData();
  },
  methods: {
    onToolbarPreparing(e){
      e.toolbarOptions.items.unshift(
        {location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '타 기관 Short Name 정보',
            icon: 'info',
            stylingMode: "outlined",
            onClick: this.showOtherHopitalAtcShortName.bind(this)
        }}
      );    
    },
    onToolbarPreparingOthers(e){
      e.toolbarOptions.items.unshift(
        {location: 'after',
          widget: 'dxButton',
          locateInMenu: 'auto',
          options: {
            text: '선택 적용',
            icon: 'save',
            stylingMode: "outlined",
            onClick: this.updateShortName.bind(this)
        }}
      );    
    },
    showOtherHopitalAtcShortName(){
      this.dataSourceOthers=[];
      this.popup.show();
      this.gridOthers.beginCustomLoading("데이터 조회 중...");
      dsu.getAxiosOBJGet("/api/MATC/selectAtcInfoHospitalOthers").then((res) => {
        this.gridOthers.endCustomLoading();
        let rtnMsg = res.data.msg;
        if (res.data.result == "SUCCESS") {
          this.dataSourceOthers = res.data.data;
        } else {
          fn.notifyError(rtnMsg);
        }
      });    
    },
    refreshData() {
     this.grid.beginCustomLoading("데이터 조회 중...");
      dsu.getAxiosOBJGet("/api/MATC/selectAtcInfoHospital").then((res) => {
        this.grid.endCustomLoading();
        let rtnMsg = res.data.msg;
        if (res.data.result == "SUCCESS") {
          this.dataSource = res.data.data;
        } else {
          fn.notifyError(rtnMsg);
        }
      });
    },
    onRowUpdating(e) {
      var params = {};
      params.atcCode = e.oldData.atcCode;
      params.atcNameKorShort = e.newData.atcNameKorShort; 
      e.newData.atcNameKorShort = e.oldData.atcNameKorShort;
      dsu.getAxiosOBJPost("/api/MATC/updateAtcInfoHospital", params).then((res) => {
        if (res.data.result == "SUCCESS") {
          fn.notifySuccess(res.data.msg);
          for (let drug of this.dataSource) {
            if (drug.atcCode ===  params.atcCode) {
              drug.atcNameKorShort = params.atcNameKorShort;
              break; 
            }
          }
        } else {
          fn.notifyError(res.data.msg);
        }
      });
    },
    async updateShortName() {
      var selectedRows = this.gridOthers.getSelectedRowsData();
      if (selectedRows.length == 0) {
        fn.notifyError("선택된 항목이 없습니다.");
        return;
      }

      var params = {};
      params.atcCode = selectedRows[0].atcCode;
      dsu.getAxiosOBJGet("/api/MATC/selectAtcInfoHospitalShortName", params).then(async (res) => {
        const response = res.data;
        if (response.result == "SUCCESS") {
          let currentShortName = '';
          const targetShortName = selectedRows[0].atcNameKorShort;
          if (response.data.length == 0) {
            currentShortName = '미등록';
          } else {
            currentShortName = response.data[0].atcNameKorShort;
          }

          let confirmMsg = selectedRows[0].atcCode + ' 코드의 Short Name을 변경 하시겠습니까? <br><br>';
          confirmMsg += '현재 : ' + currentShortName + '<br>'
          confirmMsg += '변경 : ' + targetShortName;
          

          if (await fn.confirm(confirmMsg) == false) {
            return;
          }

          params.atcNameKorShort = targetShortName; 
          dsu.getAxiosOBJPost("/api/MATC/updateAtcInfoHospital", params).then((res) => {
            if (res.data.result == "SUCCESS") {
              fn.notifySuccess(res.data.msg);
              this.refreshData();
            } else {
              fn.notifyError(res.data.msg);
            }
          });
        } else {
          fn.notifyError(res.data.msg);
        }
      });
    }
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 210px);
}
</style>
