<template>
  <div class="content-block dx-card responsive-paddings">
    <div class="adrFormContainer">
      <AdrDataForm class="adrFormContainer" :showPrint="true"/>
    </div>
  </div>
</template>

<script>
  import AdrDataForm from "../_common/ADR_DATA_FORM";
  export default {
    components: {
      AdrDataForm
    }
  };
</script>

<style lang="scss">
  .adrFormContainer {
    height: calc(100vh - 205px);
  }
</style>