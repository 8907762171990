export const userAuth = [
  {ID: 'MANAGER', Name: '기관 관리자'},
  {ID: 'ADMIN', Name: '시스템 관리자'}
];

export const job = [
  {ID: 'DOCTOR', Name: '의사'},
  {ID: 'CHEMIST', Name: '약사'},
  {ID: 'NURSE', Name: '간호사'},
  {ID: 'CENTER_MANAGER', Name: '약품안전센터 직원'}
];

export const userStatus = [
  {ID: 'ACTIVE', Name: '활성'},
  {ID: 'INACTIVE', Name: '비활성'}
];
