//import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
//Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    token: '',
    userId: '',
    userName: '',
    auth: '',
    hospitalCode: '',
    hospitalName: '',
    job: '',
    email: '',
    tel: '',
    sePort: ''
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken
    },
    setUserId(state, newUserId) {
      state.userId = newUserId
    },
    setUserName(state, newUserName) {
      state.userName = newUserName
    },
    setAuth(state, newAuth) {
      state.auth = newAuth
    },
    setHospitalCode(state, newHospitalCode) {
      state.hospitalCode = newHospitalCode
    },
    setHospitalName(state, newHospitalName) {
      state.hospitalName = newHospitalName
    },
    setJob(state, newJob) {
      state.job = newJob
    },
    setEmail(state, newEmail) {
      state.email = newEmail
    },
    setTel(state, newTel) {
      state.tel = newTel
    },
    setMenuList(state, newMenuList) {
      state.menuList = newMenuList
    },
    setSePort(state, newSePort) {
      state.sePort = newSePort
    }
  },
  getters: {
    getToken(state) {
      return `${state.token}`
    },
    getUserId(state) {
      return `${state.userId}`
    },
    getUserName(state) {
      return `${state.userName}`
    },
    getAuth(state) {
      return `${state.auth}`
    },
    getHospitalCode(state) {
      return `${state.hospitalCode}`
    },
    getHospitalName(state) {
      return `${state.hospitalName}`
    },
    getJob(state) {
      return `${state.job}`
    },
    getEmail(state) {
      return `${state.email}`
    },
    getTel(state) {
      return `${state.tel}`
    },
    getMenuList(state) {
      return `${state.menuList}`
    },
    getSePort(state) {
      return `${state.sePort}`
    }
  }
})