<template>
  <DxScrollView :ref="scrollViewRefName" height="100%" width="100%">
    <div class="content-block dx-card responsive-paddings">
      <div class="objTitle">DUR Screening Dataset</div>

      <DxDataGrid
        :ref="gridRefName"
        :show-borders="true"
        :show-column-lines="true"
        :wordWrapEnabled="true"
        @cell-prepared="onCellPrepared"
        @toolbar-preparing="onToolbarPreparing"
      >
        <DxScrolling mode="virtual" />
        <DxSorting mode="none" />

        <DxColumn caption="DUR Screening Dataset">
          <DxColumn data-field="identifier" caption="Identifier" width="85" />

          <DxColumn
            data-field="objects"
            caption="Objects"
            cell-template="objects-cell-template"
            width="150"
          />

          <DxColumn
            data-field="target_outcome"
            caption="Target Outcome"
            cell-template="target-outcome-cell-template"
            width="500"
          />

          <DxColumn
            data-field="referenced_sentences"
            caption="Referenced Sentences"
            cell-template="referenced-sentences-cell-template"
            width="500"
          />
        </DxColumn>

        <DxColumn caption="Evaluation Results">
          <DxColumn
            data-field="validity.is_valid"
            caption="Validity"
            width="65"
            cell-template="boolean-cell-template"
          />

          <DxColumn
            data-field="relevance_and_quality"
            caption="Relevance And Quality"
            cell-template="rqa-cell-template"
            width="500"
          />

          <DxColumn
            data-field="overall_assessment"
            caption="Overall Assessment"
            cell-template="asr-cell-template"
            width="500"
          />
          <DxColumn
            data-field="hallucination_check.hallucination_details"
            caption="Hallucination Check"
            cell-template="list-cell-template"
            width="500"
          />
        </DxColumn>

        <template #objects-cell-template="{ data }">
          <div class="object">Object A</div>
          <div>
            {{ data.value.object_A.code_system }},
            {{ data.value.object_A.code }}
          </div>
          <div class="objName">
            {{ data.value.object_A.name }}
          </div>
          <p></p>
          <div class="object">Object B</div>
          <div>
            {{ data.value.object_B.code_system }},
            {{ data.value.object_B.code }}
          </div>
          <div class="objName">
            {{ data.value.object_B.name }}
          </div>
        </template>

        <template #target-outcome-cell-template="{ data }">
          <div class="cellContainer">
            <p>
              <span class="objName">FSN:</span>
              <span class="value">{{ data.value[0].fsn }}</span>
            </p>
            <p>
              <span class="objName">Severity:</span>
              <span class="value">{{ data.value[0].severity }}</span>
            </p>

            <p><span class="objName">Watchful Symptoms:</span></p>
            <ul>
              <li
                v-for="(symptom, index) in data.value[0].watchful_symptoms"
                :key="index"
              >
                {{ symptom }}
              </li>
            </ul>

            <p>
              <span class="objName">Singular FSN:</span>
              <span class="value">{{ data.value[0].singularfsn }}</span>
            </p>

            <div class="recommendation">
              <p><span class="objName">Recommendation:</span></p>
              <div class="guidance">
                <p>
                  <span class="objName">Guidance:</span>
                  <span class="value">{{
                    data.value[0].recommendation[0].guidance
                  }}</span>
                </p>
              </div>
              <div class="action">
                <p>
                  <span class="objName">Action:</span>
                  <span class="value">{{
                    data.value[0].recommendation[0].action
                  }}</span>
                </p>
              </div>
            </div>

            <p>
              <span class="objName">Potential Harmful Outcome:</span>
              <span class="value">{{
                data.value[0].potential_harmful_outcome
              }}</span>
            </p>

            <p class="category">Category: {{ data.value[0].category }}</p>
          </div>
        </template>

        <template #referenced-sentences-cell-template="{ data }">
          <DxDataGrid
            :ref="gridRefName"
            :data-source="data.data.referenced_sentences"
            :show-borders="true"
            :show-column-lines="true"
            :wordWrapEnabled="true"
            @cell-prepared="onCellPreparedRS"
          >
            <DxScrolling mode="virtual" />
            <DxSorting mode="none" />
            <DxColumn
              data-field="info_type"
              caption="Info Type"
              cell-template="info_type-cell-template"
              width="130"
            />
            <DxColumn
              data-field="sentence"
              caption="Sentence"
              cell-template="sentence-cell-template"
              width="350"
            />

            <template #info_type-cell-template="{ data }">
              {{ convInfoType(data.value) }}
            </template>
            <template #sentence-cell-template="{ data }">
              <div
                class="sentence"
                v-for="(sentence, index) in data.value.split('\n')"
                :key="index"
                v-html="convScentence(sentence)"
              ></div>
            </template>
          </DxDataGrid>
        </template>

        <template #boolean-cell-template="{ data }">
          <p v-if="data.value === true" class="boolTrue">True</p>
          <p v-if="data.value === false" class="boolFalse">False</p>
        </template>

        <template #list-cell-template="{ data }">
          <ul class="cellContainer">
            <li v-for="(item, index) in data.value" :key="index">
              {{ item }}
            </li>
          </ul>
        </template>

        <template #rqa-cell-template="{ data }">
          <div class="cellContainer">
            <p>
              <span class="objName">Relevance Precision Score:</span>
              <span :class="isAbnormal(data.value.relevance_precision_score)">{{
                data.value.relevance_precision_score
              }}</span>
            </p>

            <p><span class="objName">Completeness Assessment:</span></p>
            <p class="valueLP">{{ data.value.completeness_assessment }}</p>

            <p>
              <span class="objName">Relevance Recall Score:</span>
              <span :class="isAbnormal(data.value.relevance_recall_score)">{{
                data.value.relevance_recall_score
              }}</span>
            </p>

            <p><span class="objName">Accuracy of Recommendations:</span></p>
            <p class="valueLP">{{ data.value.accuracy_of_recommendations }}</p>

            <p>
              <span class="objName">Accuracy Score:</span>
              <span :class="isAbnormal(data.value.accuracy_score)">{{
                data.value.accuracy_score
              }}</span>
            </p>

            <p>
              <span class="objName">Completeness Score:</span>
              <span :class="isAbnormal(data.value.completeness_score)">{{
                data.value.completeness_score
              }}</span>
            </p>

            <p><span class="objName">Relevance Evaluation Details:</span></p>
            <p class="valueLP">{{ data.value.relevance_evaluation_details }}</p>
          </div>
        </template>

        <template #asr-cell-template="{ data }">
          <div class="cellContainer">
            <p><span class="objName">Conclusion:</span></p>
            <p class="valueLP">{{ data.value.conclusion }}</p>

            <p><span class="objName">Clinical Significance:</span></p>
            <p class="valueLP">{{ data.value.clinical_significance }}</p>

            <div class="strengths">
              <p><span class="objName">Strengths:</span></p>
              <ul>
                <li
                  v-for="(item, index) in ensureArray(data.value.strengths)"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

            <div class="weaknesses">
              <p><span class="objName">Weaknesses:</span></p>
              <ul>
                <li
                  v-for="(item, index) in ensureArray(data.value.weaknesses)"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

            <p><span class="objName">Structure and Format:</span></p>
            <p class="valueLP">{{ data.value.structure_and_format }}</p>

            <div class="suggestions">
              <p><span class="objName">Suggestions:</span></p>
              <ul>
                <li
                  v-for="(item, index) in ensureArray(data.value.suggestions)"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </div>

            <p>
              <span class="objName">Helpfulness Score:</span>
              <span :class="isAbnormal(data.value.helpfulness_score)">{{
                data.value.helpfulness_score
              }}</span>
            </p>
          </div>
        </template>
      </DxDataGrid>
    </div>
    <div class="content-block dx-card responsive-paddings">
      <div id="btnFeedback">
        <div class="objTitle">Feedback 입력</div>
        <DxTextArea
          :disabled="loading"
          class="feedback"
          v-model:value="feedback"
        />
        <div class="btnContainer">
          <DxButton
            class="btn"
            text="Feedback 전송"
            type="normal"
            styling-mode="outlined"
            :disabled="loading"
            @click="sendFeedback($event)"
            template="sendFeedback"
          >
            <template #sendFeedback>
              <div>
                <DxLoadIndicator
                  v-if="loading"
                  width="24px"
                  height="24px"
                  :visible="true"
                />
                <span v-if="loading" class="stopWatch"
                  >{{ formattedMinutes }} : {{ formattedSeconds }}</span
                >
                <span v-if="!loading" class="btnText">Feedback 전송</span>
              </div>
            </template>
          </DxButton>
        </div>
      </div>
    </div>
    <MgMemoryItem :ref="mgMemoryGenRefName" />
    <MgMemoryItem v-show="showMgMemoryEval" :ref="mgMemoryEvalRefName" />
  </DxScrollView>
  <DxPopup
    :ref="popupRegRefName"
    :drag-enabled="false"
    :hideOnOutsideClick="false"
    :showTitle="true"
    :showCloseButton="true"
    title="복약지도문 전송"
    @shown="reqGenMgText"
  >
    <DxToolbarItem
      widget="dxButton"
      :options="{
        text: 'CDSS로 복약지도문 전송',
        onClick: this.sendMGTextFinal.bind(this),
        type: 'normal',
        stylingMode: 'outlined',
        icon: 'arrowright',
      }"
      location="after"
    />

    <DxTextArea
      id="mgText"
      :disabled="loading"
      class="mgText"
      v-model:value="mgText"
    />
    <DxLoadPanel
      :position="{ of: '#mgText' }"
      :visible="loadingMGText"
      :show-indicator="true"
      :show-pane="true"
      :shading="true"
      :hide-on-outside-click="false"
      :message="'복약지도문 생성 중...'"
    />
  </DxPopup>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
} from 'devextreme-vue/data-grid';
import MgMemoryItem from '../_common/MG_MEMORY_ITEM';
import DxTextArea from 'devextreme-vue/text-area';
import DxButton from 'devextreme-vue/button';
import DxLoadIndicator from 'devextreme-vue/load-indicator';
import DxScrollView from 'devextreme-vue/scroll-view';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';
import { useStopwatch } from 'vue-timer-hook';
import DxPopup, { DxToolbarItem } from 'devextreme-vue/popup';
import { DxLoadPanel } from 'devextreme-vue/load-panel';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    MgMemoryItem,
    DxTextArea,
    DxButton,
    DxLoadIndicator,
    DxScrollView,
    DxPopup,
    DxToolbarItem,
    DxLoadPanel,
  },
  data() {
    return {
      gridRefName: 'grid',
      feedback: '',
      loading: false,
      mgMemoryGenRefName: 'mgMemoryGen',
      mgMemoryEvalRefName: 'mgMemoryEval',
      showMgMemoryEval: false,
      data: {},
      scrollViewRefName: 'scrollView',
      stopwatch: useStopwatch(false),
      showGrid: false,
      popupRegRefName: 'popup',
      mgText: '',
      loadingMGText: false,
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    scrollView: function () {
      return this.$refs[this.scrollViewRefName].instance;
    },
    popup: function () {
      return this.$refs[this.popupRegRefName].instance;
    },
    formattedMinutes() {
      return this.stopwatch.minutes.toString().padStart(2, '0');
    },
    formattedSeconds() {
      return this.stopwatch.seconds.toString().padStart(2, '0');
    },
  },
  methods: {
    onCellPrepared(e) {
      if (e.rowType == 'header') {
        e.cellElement.bgColor = '#E4EEF7';
        e.cellElement.style.fontWeight = 'bold';
      }
    },
    onCellPreparedRS(e) {
      if (e.rowType == 'header') {
        e.cellElement.bgColor = '#f5f5f5';
        e.cellElement.style.fontWeight = 'bold';
      }
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        locateInMenu: 'auto',
        options: {
          text: '복약지도문 전송',
          icon: 'save',
          stylingMode: 'outlined',
          onClick: this.sendMG.bind(this),
        },
      });
    },
    startStopWatch() {
      this.stopwatch.reset();
      this.stopwatch.start();
    },
    sendFeedback() {
      if (!this.data.reqId || !this.data.seq) {
        fn.notifyError('Feedback 대상을 선택하세요.');
        return;
      }
      if (!this.feedback.trim()) {
        fn.notifyError('피드백을 입력하세요.');
        return;
      }

      var params = {};
      params.reqId = this.data.reqId;
      params.sTransTime = this.data.transTime;
      params.feedback = this.feedback;
      params.fromSeq = this.data.seq;
      try {
        this.loading = true;
        this.startStopWatch();
        dsu.getAxiosOBJPost('/api/MMMG/sendFeedbackMG', params).then((res) => {
          this.loading = false;
          this.stopwatch.pause();
          let response = res.data;
          if (response.result == 'SUCCESS') {
            fn.alert(
              response.msg +
                ' (' +
                this.formattedMinutes +
                ':' +
                this.formattedSeconds +
                ')'
            );
            this.$emit('refreshData', this.data.reqId);
          } else {
            fn.alert(response.msg, '오류');
          }
        });
      } catch (e) {
        this.loading = false;
        this.stopwatch.pause();
      }
    },
    setData(data) {
      var memoryTypeGen = '';
      if (data.genType == 'Initial') {
        memoryTypeGen = 'Generation';
      } else {
        memoryTypeGen = 'Feedback';
      }

      this.data = {};
      this.data = data;
      this.grid.option('dataSource', JSON.parse(data.mergedData));
      this.$refs[this.mgMemoryGenRefName].setData(
        data.memoryGen,
        memoryTypeGen,
        data.llmModelGen,
        data.responseTimeGen,
        data.llmTempGen,
        data.transTimeStr
      );
      this.$refs[this.mgMemoryEvalRefName].setData(
        data.memoryEval,
        'Evaluation',
        data.llmModelEval,
        data.responseTimeEval,
        data.llmTempEval,
        data.transTimeStr
      );
      this.feedback = '';
      if (data.memoryEval) {
        this.showMgMemoryEval = true;
      } else {
        this.showMgMemoryEval = false;
      }
      this.scrollView.scrollTo(0);
    },
    sendMG() {
      if (!this.data.reqId || !this.data.seq) {
        fn.notifyError('최종 복약지도문 생성 대상을 선택하세요.');
        return;
      }
      this.popup.show();
    },
    reqGenMgText() {
      var params = {};
      params.reqId = this.data.reqId;
      params.sTransTime = this.data.transTime;
      params.fromSeq = this.data.seq;
      try {
        this.mgText = '';
        this.loadingMGText = true;
        dsu.getAxiosOBJPost('/api/MMMG/genMGText', params).then((res) => {
          this.loadingMGText = false;
          this.loading = false;
          let response = res.data;
          if (response.result == 'SUCCESS') {
            this.mgText = response.msg;
          } else {
            fn.alert(response.msg, '오류');
          }
        });
      } catch (e) {
        this.loadingMGText = false;
      }
    },
    ensureArray(input) {
      if (Array.isArray(input)) {
        return input; // 입력이 배열이면 그대로 반환
      } else if (typeof input === 'string') {
        return [input]; // 입력이 문자열이면 배열로 변환
      } else {
        return []; // 그 외에는 빈 배열 반환
      }
    },
    isAbnormal(score) {
      var css = '';
      if (score <= 8) {
        css = 'scoreAbnormal';
      } else {
        css = 'score';
      }
      return css;
    },
    convInfoType(orgText) {
      var convText = '';

      if (orgText == 'patient_info') {
        convText = '환자 정보';
      } else if (orgText == 'current_medication') {
        convText = '현재(=기) 복용 약제';
      } else if (orgText == 'new_prescription_info') {
        convText = '신규 처방 약제';
      }

      return convText;
    },
    convScentence(orgText) {
      return orgText.replace(/<strong>/g, "<strong class='hilight'>");
    },
    sendMGTextFinal() {
      alert('개발 중.. 휴레이 완료 후 진행 예정');
    },
  },
};
</script>

<style lang="scss">
.objName {
  font-weight: bold;
}

h2 {
  color: #333;
}

.value {
  color: #555;
}

.valueLP {
  color: #555;
  margin-left: 10px;
  text-indent: 5px;
}
ul {
  padding-left: 20px;
}
li {
  list-style-type: square;
  margin: 5px 0;
}
.strengths,
.weaknesses,
.suggestions {
  margin-bottom: 15px;
}
.strengths ul,
.weaknesses ul,
.suggestions ul {
  list-style-type: disc;
  padding-left: 20px;
}
.guidance,
.action {
  padding-left: 20px;
}
.category {
  background-color: #e7e5e5;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
}

.boolTrue {
  background-color: rgb(138, 219, 138);
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
  color: rgb(55, 55, 55);
}

.boolFalse {
  background-color: #ffcccc;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  font-weight: bold;
  color: rgb(55, 55, 55);
}

.score {
  background-color: #e7e5e5;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px;
  font-weight: bold;
}

.scoreAbnormal {
  background-color: #ffcccc;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px;
  font-weight: bold;
  color: rgb(55, 55, 55);
}

.cellContainer {
  word-wrap: break-word; /* 자동 줄바꿈 */
  overflow-wrap: break-word; /* 자동 줄바꿈 */
  white-space: normal; /* 기본적으로 줄바꿈 허용 */
}

.objTitle {
  background-color: #e7e5e5;
  padding: 7px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}

.feedback {
  width: 100%;
  height: 250px;
}
.mgText {
  width: 100%;
  height: 100%;
}

.btnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-right: 10px;
}

.stopWatch {
  font-size: 16px;
  font-weight: bold;
  margin-left: 10px;
}

.btnText {
  font-size: 16px;
  font-weight: 500;
}

.object {
  background-color: #ece9bd;
  padding: 5px;
  border-radius: 5px;
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  color: rgb(55, 55, 55);
}

.sv {
  padding-bottom: 20px;
}

.svTxt {
  white-space: pre-wrap;
  padding: 10px;
  font-size: 16px;
}

.sentence {
  margin-top: 5px;
  margin-bottom: 5px;
}
.hilight {
  padding: 4px;
  background-color: #ece9bd;
  border-radius: 5px;
  display: inline-block;
  font-weight: bold;
  color: rgb(55, 55, 55);
}
</style>
