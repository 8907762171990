<template>
  <div class="content-block dx-card responsive-paddings">
      <DxToolbar>
        <DxItem :options="dateBoxOptionsStart"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>        
        <DxItem :options="dateBoxOptionsEnd"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>   
        <DxItem :options="buttonOptionsSearch"
                location="center"
                locate-in-menu="never"
                widget="dxButton"/>                    
      </DxToolbar>
  </div>
  
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      @rowDblClick="onRowDblClick">
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="virtual"/>
      <DxSorting mode="multiple"/>
      <DxSelection mode="single" />

      <DxColumn
          data-field="transTime"
          caption="인쇄 날짜"
          :header-filter="{ allowSearch: true }"
          cell-template="transTime-cell-template">
      </DxColumn>

      <DxColumn caption="등록 희망자">
        <DxColumn
            data-field="ptName"
            caption="성명"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptNumber"
            caption="병원등록번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptRrn"
            caption="생년월일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="ptTel"
            caption="휴대폰 번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
      </DxColumn>

      <DxColumn caption="카드 인쇄 정보">
        <DxColumn
            data-field="d1OccurenceDate"
            caption="발생일"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="mD1CausativeDrug"
            caption="원인약물"
            :header-filter="{ allowSearch: true }"
            width="250"
            cell-template="text-area-template">
        </DxColumn>

        <DxColumn
            data-field="mD1Symptom"
            caption="증상"
            :header-filter="{ allowSearch: true }"
            width="250"
            cell-template="text-area-template">
        </DxColumn>

        <DxColumn
            data-field="mD2CiGroup"
            caption="금기 성분(군)"
            :header-filter="{ allowSearch: true }"
             width="250"
            cell-template="text-area-template">
        </DxColumn>
        
        <DxColumn
            data-field="mD2CrhiGroup"
            caption="주의 성분(군)"
            :header-filter="{ allowSearch: true }"
             width="250"
            cell-template="text-area-template">
        </DxColumn>
        
        <DxColumn
            data-field="mD2OaiGroup"
            caption="복용가능 성분(군)"
            :header-filter="{ allowSearch: true }"
             width="250"
            cell-template="text-area-template">
        </DxColumn>       
        
        <DxColumn
            data-field="mD2ExpertOpinion"
            caption="전처치(전문가 의견)"
            :header-filter="{ allowSearch: true }"
             width="250"
            cell-template="text-area-template">
        </DxColumn>   
      </DxColumn>
      
      <DxColumn
          data-field="d1List"
          caption="약물이상사례(개별사례)"
          :header-filter="{ allowSearch: false }"
          cell-template="d1-cell-template">
      </DxColumn>

      <DxColumn caption="약물이상사례(통합조정)">
        <DxColumn
            data-field="d1ManagementNumber"
            caption="원내_이상사례_관리번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2CurrentStatus"
            caption="현재상태"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
        
        <DxColumn
            data-field="d2Symptom"
            caption="증상"
            :header-filter="{ allowSearch: true }"
            cell-template="symptom-cell-template">
        </DxColumn>

        <DxColumn
            data-field="d2CiGroup"
            caption="금기 성분(군)"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>        

        <DxColumn
            data-field="d2DrugSideEffectsType"
            caption="유형"
            minWidth="200"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2CrhiGroup"
            caption="주의 성분(군)"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>                

        <DxColumn
            data-field="d2Causality"
            caption="인과성"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2OaiGroup"
            caption="복용가능 성분(군)"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>  

        <DxColumn
            data-field="d2Severity"
            caption="중증도"
            :header-filter="{ allowSearch: true }">
        </DxColumn>

        <DxColumn
            data-field="d2ExpertOpinion"
            caption="전문가의견"
            :header-filter="{ allowSearch: true }"
             width="250"
            cell-template="text-area-template">
        </DxColumn>
      </DxColumn>

      <template #d1-cell-template="{data}">
        <AdrDataD1 :d1ListData="data.value"/>
      </template>        

      <template #symptom-cell-template="{data}">
        <GridSymptom :symptomData="data.value"/>
      </template>        
      
      <template #drug-cell-template="{data}">
        <GridDrug :drugData="data.value"/>
      </template>     
      
      <template #transTime-cell-template="{data}">
        {{formatDateString(data.value)}}
      </template>         
      
      <template #text-area-template="{data}">
        <TextAreaTemplate :text="data.value"/>
      </template>  
    </DxDataGrid>
    <DxPopup :ref="popupRegRefName"
        height="calc(100vh * 0.9)"
        width="90%"
        :drag-enabled="false"
        :hideOnOutsideClick="true"
        :showTitle="true"
        title="부작용 정보 등록">
          <div class="content-block dx-card responsive-paddings">
            <div class="adrFormContainer">
              <AdrDataForm class="dataView"
                :ref="adrDataRegRefName"
                :showPrint="true"
                :hideNewbtn="true"
                @refreshDataParent="search"/>
            </div>
          </div>
    </DxPopup>
  </div>
</template>

<script>
  import {DxDataGrid,
         DxColumn,
         DxScrolling,
         DxSorting,
         DxHeaderFilter,
         DxSearchPanel,
         DxSelection} from "devextreme-vue/data-grid";
  import AdrDataD1 from "../_common/ADR_DATA_D1";                
  import GridSymptom from "../_common/GRID_SYMPTOM";
  import GridDrug from "../_common/GRID_DRUG";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import {userStatus} from "../../utils/syscodedata";
  import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
  import store from "../../store";
  import TextAreaTemplate from "../_common/TEXT_AREA_TMPLATE";
  import AdrDataForm from "../_common/ADR_DATA_FORM";
  import DxPopup from "devextreme-vue/popup";   

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  var defaultStartDate = new Date(year, month - 11, 1);
  var defaultEndDate = new Date(year, month + 1, 0);

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      GridSymptom,
      GridDrug,
      DxToolbar,
      DxItem,
      AdrDataD1,
      DxSelection,
      TextAreaTemplate,
      AdrDataForm,
      DxPopup
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: [],
        recStatus: userStatus,
        popupRegRefName: 'popupReg',
        adrDataRegRefName: 'adrDataReg',
        searchCondition: {startDate: defaultStartDate, endDate: defaultEndDate},
        dateBoxOptionsStart: {
          width: 190,
          value: defaultStartDate,
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 190,
          value: defaultEndDate,
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }               
      }
    },
    computed: {
      grid: function () {
        return this.$refs[this.gridRefName].instance;
      }
    },
    methods: {
      search(e) {
        //var ports = [49160, 50297, 62192];
        var ports = [49160];
        var params = JSON.parse(JSON.stringify(this.searchCondition));
        params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
        params.endDate = dsu.getFormatDate(this.searchCondition.endDate);
        params.hospitalCode = store.getters.getHospitalCode;        
        this.grid.beginCustomLoading("데이터 조회 중...");
        e.component.option('disabled', true);  
        var next = true;
        for (let i = 0; i < ports.length; i++){
          if (next) {
            dsu.getAxiosOBJGetWithOutHeader('http://localhost:' + ports[i], params).then(res => {
              this.grid.endCustomLoading();
              e.component.option('disabled', false);
              let response = res.data;
              if (response.result == 'SUCCESS') {
                this.dataSrouceRaw = response.data;
                this.setGridData(response.data);
                next = false;
              } else {
                fn.notifyError("Search Engine 예외상황 발생 : " + response.msg);
              }
            }).catch(() => {
              this.grid.endCustomLoading();
              e.component.option('disabled', false);
              fn.alert("MyADR Search Engine 연결 실패, MyADR Printer 설치 후 진행 필요", "확인" );
            });
          }
        }
      },
      setGridData(data)
      {
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('dataSource', data);
      },
      formatDateString(dateString) {
        const year = dateString.substring(0, 4);
        const month = dateString.substring(4, 6);
        const day = dateString.substring(6, 8);
        const hour = dateString.substring(8, 10);
        const minute = dateString.substring(10, 12);
        const second = dateString.substring(12, 14);
        return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      },
      onRowDblClick(e){
        let selectedADRData = e.data;
        this.$refs[this.adrDataRegRefName].mount();
        this.$refs[this.adrDataRegRefName].setLocalData(selectedADRData);
        this.$refs[this.popupRegRefName].instance.show();
      },
    }
  };
</script>

<style scoped>
  #grid{
    max-height: calc(100vh - 330px);
  }
  
  .dataView{
    margin-top:15px;
  }

  .adrFormContainer {
    height: calc(100vh * 0.9 - 135px);
  }
</style>