<template>
  <DxScrollView v-if="beforeReq" height="100%" width="100%" class="with-footer single-card">
     <div class="dx-card content">
        <div class="header">
          <img class="logo" :src="require('@/assets/ci.png')">  
        </div>
        <div class="notice">
          <b>환자 약물 부작용 정보 열람</b>
          <br><br>
          <div class="text">
            간편 인증을 통해 본인 확인 후 약물 부작용 정보 열람이 가능합니다.
          </div>          
        </div>
        <div class="iOrF">
          <DxButtonGroup
            :items="[{
              text: '환자',
              alignment: 'left',
              value: 'PT'
            },
            {
              text: '보호자',
              alignment: 'right',
              value: 'AP'
            }]"
            :selected-item-keys="['left']"
            key-expr="alignment"
            styling-mode="outlined"
            @item-click="iOrFitemClick"
          />
        </div>
        <div class="snsCert">
          <img v-for="(image, index) in images" :key="index" 
            :src="image.src"
            :class="{ highlighted: selectedImage === index }"
            @click="onClickCertType(index)"
            class="snsIcon"
          />
         </div> 
  
         <div class="reqBtn">
          <DxButton class="btn" :text="btnName" @click="reqCert" styling-mode="outlined"/>
         </div>
      </div>
  </DxScrollView>

  <DxScrollView v-if="afterReq" height="100%" width="100%" class="with-footer single-card">
     <div class="dx-card content">
        <div class="header">
          <img class="logo" :src="require('@/assets/ci.png')">  
        </div>
        <div class="notice">
          <b>환자 약물 부작용 정보 열람</b>
          <br><br>
          <div class="passKeyContainer" v-if="certType ==='SMS'">
            <div  class="text">
              문자로 인증번호가 발송되었습니다. 인증번호 입력 후 확인 버튼을 눌러주세요.
            </div>   
            <br>
            <br>
            <DxTextBox
                width="90%"
                v-model:value="passKey"
                styling-mode="filled"
                :input-attr="{autocomplete:'one-time-code'}">
                <DxTextBoxButton
                  name="clock"
                    :options="{icon: 'clock',
                                text: remainTime}"
                    location="after"/>
              </DxTextBox>
          </div>  
          <div v-else class="text">
            인증 요청이 완료되었습니다. {{ this.certName  }} 앱에서 인증 완료 후 확인 버튼을 눌러주세요.
          </div>    
          <br><br> 
        </div>
         <div class="reqBtn">
          <DxButton class="btn" text="확인" @click="confirmCert" styling-mode="outlined"/>
         </div>
      </div>
  </DxScrollView>

    <DxScrollView v-if="incorrectUrl" height="100%" width="100%"  class="with-footer single-card">
    <div class="dx-card content">
        <div class="header">
          <img class="logo" :src="require('@/assets/ci.png')">  
        </div>
        <div class="notice">
          <b>잘못된 접근 입니다.</b>
          <br><br>
          <div class="text">
            URL 확인 후 다시 시도해 주세요.
          </div>
          <br><br> 
        </div>
     </div>
  </DxScrollView>

  <div v-if="showADRData" 
      class="pdfContainer"> 
      <div class="btnContainer">
        <DxButton class="btn"
                :width="150"
                text="PDF 다운로드"
                type="normal"
                styling-mode="contained"
                icon="download"
                @click="downloadPDF($event)"/>
        <DxButton v-if="!isPreviewMode" class="btn"
                :width="150"
                text="URL 복사"
                type="normal"
                styling-mode="contained"
                icon="copy"
                @click="copyURL($event)"/>
      </div>

      <div v-for="page in pdfPages" :key="page">
        <VuePDF :pdf="pdfData" :page="page" fit-parent/> 
      </div>
  </div>
</template>

<script>
  import DxScrollView from "devextreme-vue/scroll-view";
  import DxButton from "devextreme-vue/button";
  import jsPDF from "jspdf"; 
  import "jspdf-autotable";
  import { VuePDF, usePDF } from '@tato30/vue-pdf';
  import DxButtonGroup from 'devextreme-vue/button-group';
  import {DxTextBox, 
          DxButton as DxTextBoxButton } from 'devextreme-vue/text-box';
  import dsu from '@/utils/dataSourceUtils';
  import fn from "@/utils/function"; 
    
  export default {
    components: {
      DxScrollView,
      DxButton,
      VuePDF,
      DxButtonGroup,
      DxTextBox,
      DxTextBoxButton
    },
    props: {
      isPreviewMode: Boolean
    },
    data() {
        return {
          url: '',
          certType: '',
          certName: '',
          btnName: '',
          beforeReq: false,
          afterReq: false,
          incorrectUrl: false,
          showADRData: false,
          reqId: '',
          selectedImage: null,
          selectedPtUrl: '',
          images: [
            { src: require('@/assets/sms.png'), certType: 'SMS' },
            { src: require('@/assets/kakao.jpg'), certType: 'KAKAO' },
            { src: require('@/assets/naver.jpg'), certType: 'NAVER' },
            { src: require('@/assets/pass.jpg'), certType: 'PASS' }
          ],
          iType:'PT',
          pdfDoc: undefined,
          adrData: {},
          adrDataD1: [],
          pdfData: undefined,
          pdfPages: undefined,
          passKey: '',
          totalTime: 0,
          remainTime: '',
          timer: undefined
        }
    },
    beforeMount(){
      this.url = this.$route.query.c;
      this.onClickCertType(0);

      if(!this.isPreviewMode){
        if (this.url) {
          var params = {url: this.url, exHst:'Y'};
          dsu.getAxiosOBJPost('/api/selectADRDataPublic', params).then(res => { 
            let response = res.data;
            if (response.result == 'SUCCESS') {
              this.changeStatus('SHOW_ADR_DATA');
            } else {
              if (response.data == 'BadURL') {
                this.changeStatus('INCORRECT_URL');  
              } else {
                this.changeStatus('BEFORE_REQ');  
              }
            }
          });

        } else {
          this.changeStatus('INCORRECT_URL');
          return;
        }
      } else {
        this.changeStatus('SHOW_ADR_DATA');
      }
    },
  methods: {
      iOrFitemClick(e) {
      this.iType = e.itemData.value;
        this.setReqBtnValue();
      },
      onClickCertType(index) {
        this.selectedImage = index;
        this.certType = this.images[index].certType;
        this.setReqBtnValue();
      },
      setReqBtnValue() {
        if (this.certType == 'KAKAO') {
          this.certName = 'Kakao 인증'
        } else if (this.certType == 'NAVER') {
          this.certName = 'Naver 인증'
        } else if (this.certType == 'PASS') {
          this.certName = 'Pass 인증'
        }else if (this.certType == 'SMS') {
          this.certName = '문자 인증'
        }

        let iName = this.iType == 'PT' ? '환자' : '보호자';
        this.btnName = iName + ' ' + this.certName + '하기';
      },
      changeStatus(status) {
        if (status == 'BEFORE_REQ') {
          this.beforeReq = true;
          this.afterReq = false;
          this.incorrectUrl = false;
          this.showADRData = false;
        } else if (status == 'AFTER_REQ') {
          this.beforeReq = false;
          this.afterReq = true;
          this.incorrectUrl = false;
          this.showADRData = false;
          if (this.certType == "SMS") {
            this.startTimer();  
          }
        } else if (status == 'INCORRECT_URL') {
          this.beforeReq = false;
          this.afterReq = false;
          this.incorrectUrl = true;
          this.showADRData = false;
        } else if (status == 'SHOW_ADR_DATA') {
          this.beforeReq = false;
          this.afterReq = false;
          this.incorrectUrl = false;
          this.showADRData = true;
          if (this.isPreviewMode) {
            this.getADRDataPrivate();  
          } else {
            this.getADRData();  
          }
        } else if (status == 'CLEAR') {
          this.beforeReq = false;
          this.afterReq = false;
          this.incorrectUrl = false;
          this.showADRData = false;
        }
      },
      startTimer() {
        this.totalTime = 5 * 60;
        this.passKey = '';
        this.setTime();
        this.timer = setInterval(() => {
            if (this.totalTime > 0) {
              this.totalTime--;
              this.setTime();
            } else {
              this.remainTime = '시간 초과';
              clearInterval(this.timer);
            }
          
          }, 1000);
      },
      setTime() {
        var minutes = Math.floor(this.totalTime / 60);
        var seconds = this.totalTime % 60;
       this.remainTime = `${minutes < 10 ? '0' : ''}${minutes} : ${seconds < 10 ? '0' : ''}${seconds}`;
      },
      getADRDataPrivate() {
        var params = { url: this.selectedPtUrl };
        if (params.url) {
          dsu.getAxiosOBJGet('/api/MRPM/selectADRData', params).then(res => {
            let response = res.data;
            if (response.result == 'SUCCESS') {
              let adrData = response.data[0];
              this.adrData = adrData;
              dsu.getAxiosOBJGet('/api/MRPM/selectADRDataD1', params).then(res => {
                let response = res.data;
                if (response.result == 'SUCCESS') {
                  let adrDataD1 = response.data;
                  this.adrDataD1 = adrDataD1;
                  this.generatePDF();
                } else {
                  fn.alert(response.msg, '확인').then(() => {
                    location.reload();
                  }
                  );
                }
              });
            } else {
              fn.alert(response.msg, '확인').then(() => {
                location.reload();
              }
              );
            }
          });
        }
      },
      getADRData() {
        var params = {url: this.url};
          dsu.getAxiosOBJPost('/api/selectADRDataPublic', params).then(res=>{
            let response = res.data;
            if (response.result == 'SUCCESS') {
                let adrData = response.data;
                this.adrData = adrData;
                dsu.getAxiosOBJPost('/api/selectADRDataD1Public', params).then(res=>{
                  let response = res.data;
                  if(response.result == 'SUCCESS'){
                    let adrDataD1 = response.data;
                    this.adrDataD1 = adrDataD1;
                    this.generatePDF();
                  }else{
                    fn.alert(response.msg, '확인').then(() => {
                        location.reload();
                      }
                    );
                  }
                });
            }else{
                fn.alert(response.msg, '확인').then(() => {
                        location.reload();
                      }
                );
            }
          });
      },
      refresh(url) {
        this.selectedPtUrl = url;
        this.changeStatus('SHOW_ADR_DATA');
      },
      clear() {
        this.pdfData = undefined;
        this.changeStatus('CLEAR');
      },
      reqCert(e) {
        if (!this.url) {
          this.changeStatus('INCORRECT_URL');
          return;
        }
        e.component.option('disabled', true);  

        var params = {};
        params.url = this.url;
        params.certType = this.certType;
        params.iType = this.iType;
        
        dsu.getAxiosOBJPost('/uri', params).then(res => {
          let response = res.data;
          e.component.option('disabled', false);  
          if (response.result == 'SUCCESS') {
            this.changeStatus('AFTER_REQ');
            this.reqId = response.data;
          } else {
            fn.alert(response.msg, '확인');
          }
        });
      },
      confirmCert(e) {
        e.component.option('disabled', true);  
        var params = {};
        params.reqId = this.reqId;
        if (this.certType == 'SMS') {
          params.passKey = this.passKey;
          if (!params.passKey) {
            fn.alert('인증번호를 입력해 주세요.', '확인');
            e.component.option('disabled', false);  
            return;
          }
        }
        dsu.getAxiosOBJPost('/uci', params).then(res => {
          let response = res.data;
          e.component.option('disabled', false);  
          if (response.result == 'SUCCESS') {
            this.changeStatus('SHOW_ADR_DATA');
          } else {
            if (response.data == 'expire') {
                fn.alert(response.msg, "확인").then(() => {
                location.reload();
              });
            } else {
              fn.alert(response.msg, '확인');
            }
        }});
      },
      copyURL() {
        window.navigator.clipboard.writeText('https://myadr.care/p?c=' + this.url).then(() => {
            fn.notifySuccess("복사 완료");
          });
      },
      makeTable(doc, subject, data){
        doc.autoTable({
                      theme: 'grid',
                      styles: {halign: 'left', fontSize: 8, font: "malgun"},
                      rowPageBreak: 'auto',
                      head: [
                        [
                          {
                            content: subject,
                            colSpan: 4,
                            styles: { halign: 'left', fillColor: 199, textColor: 0},
                          },
                        ]
                      ],
                      columns: [
                        { dataKey: 'd1' },
                        { dataKey: 'd2' },
                        { dataKey: 'd3' },
                        { dataKey: 'd4' }
                      ],
                      columnStyles: {d1: { fillColor: 255, textColor: 0, fontStyle: 'bold', cellWidth: 85 },
                                     d2: { cellWidth: 172},
                                     d3: { fillColor: 255, textColor: 0, fontStyle: 'bold', cellWidth: 85},
                                     d4: { cellWidth: 172}},
                      body: JSON.parse(JSON.stringify(data)),
                      showHead: 'firstPage'
                    });        
      },
      convDrugInfo(drugInfo){
        var rtnValue = '';
        if(drugInfo){
          // rtnValue = JSON.parse(drugInfo).map(row=>('[' + row.drugType + ', ' + row.drugCode + '] ' + row.drugName)).join('\n');
          rtnValue = JSON.parse(drugInfo).map(row=>(row.drugName.split('(')[0])).join(', ');
        }
        return rtnValue;
      },
      convSymptomInfo(symptomInfo){
        var rtnValue = '';
        if(symptomInfo){
          // rtnValue = JSON.parse(symptomInfo).map(row=>('[' + row.symptomCode + '] ' + row.symptomNameKor + ' , ' + row.symptomNameEng)).join('\n');
          rtnValue = JSON.parse(symptomInfo).map(row=>(row.symptomNameKor)).join(', ');
        }
        return rtnValue;
      },
      generatePDF(){
        var doc = new jsPDF('p', 'pt', 'a4');
        doc.addFont(require('@/assets/malgun.ttf'), 'malgun', 'normal');
        doc.setFont('malgun');
        doc.autoTable({
                      theme: 'grid',
                      styles: {halign: 'center', fontSize: 12, font: "malgun"} ,
                      head: [[{
                            content: '약물부작용 정보공유 서식지',
                            styles: {halign: 'center', fillColor: [49, 100, 205], textColor: 255, fontStyle: 'bold' },
                          }]]
                    });
        //등록 희망자
        var data = [{d1: '성명', d2: this.adrData.ptName, d3: '병원등록번호', d4: this.adrData.ptNumber},
                   {d1: '생년월일', d2: this.adrData.ptRrn, d3: '휴대폰 번호', d4: this.adrData.ptTel},
                   {d1: '전자메일', d2: this.adrData.ptEmail, d3: '주소', d4: this.adrData.ptAddress}];
        this.makeTable(doc, '등록희망자', data);
   
        //약물이상사례(개별사례)
        for(let i=0; i<this.adrDataD1.length; i++){
          this.adrDataD1[i].d1CausativeDrug = this.convDrugInfo(this.adrDataD1[i].d1CausativeDrug);
          this.adrDataD1[i].d1Symptom = this.convSymptomInfo(this.adrDataD1[i].d1Symptom);
          data = [{d1: '원인약물',d2: {content: this.adrDataD1[i].d1CausativeDrug, styles: {  textColor: 0, fontStyle: 'bold', fillColor: [234, 234, 234]}}, d3: '발생일', d4: this.adrDataD1[i].d1OccurenceDate},
                  {d1: '증상', d2: this.adrDataD1[i].d1Symptom, d3: '인과성', d4: this.adrDataD1[i].d1Causality},
                  {d1: '사례_설명', d2: this.adrDataD1[i].d1CaseDescription, d3: '중증도', d4: this.adrDataD1[i].d1Severity}];

          let title = '';
          if (this.adrDataD1.length == 1) {
            title = '약물이상사례(개별사례)';
          }else{
            title = '약물이상사례(개별사례) - ' + (i + 1);
          }

          this.makeTable(doc, title, data);
        }
        
        //약물이상사례(통합조정)
        this.adrData.d2Symptom = this.convSymptomInfo(this.adrData.d2Symptom);
        this.adrData.d2CiGroup = this.convDrugInfo(this.adrData.d2CiGroup);
        this.adrData.d2CrhiGroup = this.convDrugInfo(this.adrData.d2CrhiGroup);
        this.adrData.d2OaiGroup = this.convDrugInfo(this.adrData.d2OaiGroup);
        data = [{d1: '현재상태', d2: this.adrData.d2CurrentStatus, d3: '증상', d4: this.adrData.d2Symptom},
                {d1: {content: '금기 성분(군)', styles: { fontStyle: 'bold', fillColor: [241, 64, 45]}}, d2: {content: this.adrData.d2CiGroup, styles: { fontStyle: 'bold', fillColor: [249, 202, 196]}}, d3: '유형', d4: this.adrData.d2DrugSideEffectsType },
                {d1: {content: '주의 성분(군)', styles: { fontStyle: 'bold', fillColor: [245, 194, 91]}}, d2: {content: this.adrData.d2CrhiGroup, styles: { fontStyle: 'bold', fillColor: [252, 237, 205]}}, d3: '인과성', d4: this.adrData.d2Causality},
                {d1: {content: '복용가능 성분(군)', styles: { fontStyle: 'bold', fillColor: [98, 197, 92]}},d2: {content: this.adrData.d2OaiGroup, styles: { fontStyle: 'bold', fillColor: [214, 243, 212]}}, d3: '중증도', d4: this.adrData.d2Severity},
                {d1: '전문가의견', d2: this.adrData.d2ExpertOpinion, d3: '확진검사', d4: this.adrData.d2ConfTest}];
        this.makeTable(doc, '약물이상사례(통합조정)', data);

        //약물이상사례(통합조정)
        data = [{d1: '의료기관명', d2: this.adrData.d3HospitalName, d3: '의료기관코드', d4: this.adrData.d3HospitalCode},
                {d1: '최종평가자 직역', d2: this.adrData.d3FinalEvaluator, d3: '최종평가자 전문과목', d4: this.adrData.d3MedicalDepartment},
                {d1: '평가부서', d2: this.adrData.d3EvaluationDepartment, d3: '평가부서 전화번호', d4: this.adrData.d3ManagerTel}];
        this.makeTable(doc, '의료기관', data);

        //기타
        data = [{d1: '최초작성일', d2: this.adrData.d4InsertTime, d3: '서식수정일', d4: this.adrData.d4UpdateTime}];
        this.makeTable(doc, '기타', data);        
        
        const { pdf, pages } = usePDF(doc.output('bloburl', {filename: '정보.pdf'}));
        this.pdfData = pdf;
        this.pdfPages = pages;
        this.pdfDoc = doc;
      },
      downloadPDF(){
        this.pdfDoc.save('약물부작용 정보.pdf');
      }
    }
  };
</script>

<style lang="scss">
  @import "../themes/generated/variables.base.scss";
  .notice{
    font-size: 17px;
    text-align: left;
    .text{
      text-indent: 0.4em;
    }
    .passKeyContainer{
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .iOrF{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .snsCert{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    .snsIcon{
      margin: 0 7px;
      width: 20%;
      border-radius: 15%;
    }
  }

  .highlighted {
    box-shadow: 0 0 10px 5px rgba(140, 140, 140, 0.5); 
  }

  .reqBtn{
    width: 100%;
    display: flex;
    margin-top: 35px;
    justify-content: center;
    .btn{
      width: 90%;
      height: 50px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .single-card {
    width: 100%;
    height: 100%;

    .dx-card {
      width: 400px;
      margin: auto auto;
      padding: 40px;
      flex-grow: 0;

      .screen-x-small & {
        width: 100%;
        height: 100%;
        border-radius: 0;
        box-shadow: none;
        margin: 0;
        border: 0;
        flex-grow: 1;
      }

      .header {
        margin-bottom: 30px;
        text-align: center;
      
        .logo{
          width: 70%;
        }
      }
    }
  }

  .pdfContainer{
      width: 100%;
      text-align: center;
      .btnContainer{
        width: 100%;
        display: flex;
        justify-content: center;
        .btn{
            font-size: 16px;
            font-weight: bold;
            height: 45px;
            margin: 5px;
          }
    }
  }
</style>
