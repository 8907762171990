<template>
  <div class="content-block dx-card responsive-paddings">
      <DxToolbar>
        <DxItem :options="dateBoxOptionsStart"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>        
        <DxItem :options="dateBoxOptionsEnd"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>   
        <DxItem :options="buttonOptionsSearch"
                location="center"
                locate-in-menu="never"
                widget="dxButton"/>                    
      </DxToolbar>
  </div>

  <div class="content-block dx-card responsive-paddings">
      <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        :focused-row-enabled="true">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxColumn
          data-field="userId"
          caption="ID">
        </DxColumn>
        
        <DxColumn
          data-field="userName"
          caption="이름">
        </DxColumn>
    
        <DxColumn
          data-field="auth"
          caption="권한">  
          <DxLookup :data-source="userAuth"
                    value-expr="ID"
                    display-expr="Name"/>
        </DxColumn>
      
        <DxColumn
          data-field="loginCnt"
          caption="로그인 횟수">
        </DxColumn>

        <DxMasterDetail
          id='detail'
          :enabled="true"
          template="master-detail"/>
          <template #master-detail="{data}">
            <Detail :master-detail-data="data"/>
          </template>      
      </DxDataGrid>
    
  </div>
</template>

<script>
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import {DxDataGrid,
          DxScrolling,
          DxColumn,
          DxSorting,
          DxSearchPanel,
          DxHeaderFilter,
          DxMasterDetail,
          DxLookup} from "devextreme-vue/data-grid";
  import Detail from "../_common/LOGIN_HST_DETAIL";
  import fn from "../../utils/function";
  import dsu from "../../utils/dataSourceUtils";
  import {userAuth} from "../../utils/syscodedata";

  var endDate = new Date();
  var startDate = new Date();
  startDate.setDate(endDate.getDate() - 7);
  var defaultStartDate = startDate;
  var defaultEndDate = endDate;
 
  export default {
    components: {
      DxToolbar,
      DxItem,
      DxDataGrid,
      DxScrolling,
      DxColumn,
      DxSorting,
      DxSearchPanel,
      DxHeaderFilter,
      DxMasterDetail,
      Detail,
      DxLookup
    },
    data() {
      return {
        userAuth: userAuth,
        gridRefName: 'dataGrid',
        searchCondition: {startDate: defaultStartDate, endDate: defaultEndDate},
        loginHstList: [],
        dateBoxOptionsStart: {
          width: 190,
          value: defaultStartDate,
          text: "시작",
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 190,
          value: defaultEndDate,
          text: "시작",
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }       
      };
    },
    methods: {
      search(){
        var params = JSON.parse(JSON.stringify(this.searchCondition));
        params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
        params.endDate = dsu.getFormatDate(this.searchCondition.endDate);

        dsu.getAxiosOBJGet('/api/AVUL/selectLoginCntByUser', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      setGridData(data)
      {
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('keyExpr', 'userId');
        grid.option('dataSource', data);
      }
    }   
  };
</script>

<style scoped>
#grid{
  max-height: calc(100vh - 330px);
}
</style>