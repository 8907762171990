<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @rowDblClick="onRowDblClick"
      @row-removing="onRowRemoving"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxEditing :allow-updating="false" :allow-adding="false" :allow-deleting="true"/>
      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="reqId"
        caption="Req ID"
        :header-filter="{ allowSearch: true }"
      />
      
      <DxColumn
        data-field="transTime"
        caption="날짜"
        :header-filter="{ allowSearch: true }"
      />
      
      <DxColumn
        data-field="d1ManagementNumber"
        caption="원내_이상사례_관리번호"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="requestUserName"
        caption="요청자"
        :header-filter="{ allowSearch: true }"
      />
       
      <DxColumn
        data-field="ptName"
        caption="성명"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="ptNumber"
        caption="병원등록번호"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
        data-field="d1OccurenceDate"
        caption="발생일"
        :header-filter="{ allowSearch: true }"
      />

      <DxColumn
          data-field="d1CausativeDrug"
          caption="원인약물"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>

      <DxColumn
          data-field="d1Symptom"
          caption="증상"
          :header-filter="{ allowSearch: true }"
          cell-template="symptom-cell-template">
      </DxColumn>

      <DxColumn
          data-field="d2CiGroup"
          caption="금기 성분(군)"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>      
        
      <DxColumn
          data-field="d2CrhiGroup"
          caption="주의 성분(군)"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>     
                  
      <DxColumn
          data-field="d2OaiGroup"
          caption="복용가능 성분(군)"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>  

      <template #symptom-cell-template="{data}">
          <GridSymptom :symptomData="data.value"/>
      </template>        
      
      <template #drug-cell-template="{data}">
          <GridDrug :drugData="data.value"/>
      </template>   

    </DxDataGrid>
    <DxPopup :ref="popupRegRefName"
        height="calc(100vh * 0.9)"
        width="90%"
        :drag-enabled="false"
        :hideOnOutsideClick="true"
        :showTitle="true"
        title="부작용 정보 등록">
        <div class="content-block dx-card responsive-paddings">
          <div class="adrFormContainer">
            <AdrDataForm class="dataView"
              :ref="adrDataRegRefName"
              :isIFMode="true"
              @refreshDataParent="search"/>
          </div>
        </div>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxSearchPanel,
  DxEditing
} from "devextreme-vue/data-grid";
import GridSymptom from "../_common/GRID_SYMPTOM";
import GridDrug from "../_common/GRID_DRUG";
import DxPopup from "devextreme-vue/popup";        
import AdrDataForm from "../_common/ADR_DATA_FORM";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxSearchPanel,
    GridSymptom,
    GridDrug,
    DxEditing,
    DxPopup,
    AdrDataForm
  },
  data() {
    return {
      gridRefName: "grid",
      popupRegRefName: 'popupReg',
      adrDataRegRefName: 'adrDataReg',
      selectedADRDataIF: {}
    };
  },
  mounted() {
    this.search();  
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    }
  },
  methods: {
    search() {
      this.$refs[this.popupRegRefName].instance.hide();
      this.grid.beginCustomLoading("데이터 조회 중...");
      dsu.getAxiosOBJGet('/api/MRCV/selectADRDataIF').then(res=>{
        this.grid.endCustomLoading();
        let response = res.data;
        if(response.result == 'SUCCESS'){
          var data = response.data;
         data.forEach(item => {
            item.d1OccurenceDate = this.findEarliestDate(item.d1List);
            var d1List = JSON.parse(item.d1List);
            item.d1CausativeDrug = d1List.reduce((acc, item2) => {
              return acc.concat(item2.d1CausativeDrug);
            }, []);
            item.d1CausativeDrug = JSON.stringify(item.d1CausativeDrug);
            item.d1Symptom = d1List.reduce((acc, item2) => {
              return acc.concat(item2.d1Symptom);
            }, []);

            item.d1Symptom = item.d1Symptom.map(item2 => {
              item2.symptomNameKor = item2.symptomName;
              return item2;
            });
            item.d1Symptom = JSON.stringify(item.d1Symptom);
          });
          this.setGridData(data);
          this.$emit("refreshMenu");
        }else{
          fn.notifyError(response.msg);
        }
      });
    },
    setGridData(data){
      const grid = this.$refs[this.gridRefName].instance;
      grid.option('keyExpr', 'reqId');
      grid.option('dataSource', data);
    },
    findEarliestDate(jsonString) {
      var jsonArray = JSON.parse(jsonString); 
      let earliestDate = '99999999';
      jsonArray.forEach(item => {
        if (item.d1OccurenceDate < earliestDate) {
          earliestDate = item.d1OccurenceDate;
        }
      });
      return earliestDate;
    },
    onRowDblClick(e){
      this.selectedADRDataIF = e.data;
      this.$refs[this.adrDataRegRefName].mount();
      this.$refs[this.adrDataRegRefName].setIFData(this.selectedADRDataIF);
      this.$refs[this.popupRegRefName].instance.show();
    },
    onRowRemoving(e) {
        e.cancel = true;
        dsu.getAxiosOBJPost('/api/MRCV/deleteADRDataIF', e.data).then(res => {
          this.search();
            let response = res.data;
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);  
            }
        });
      }
  },
};
</script>

<style scoped>
  #grid {
    max-height: calc(100vh - 210px);
  }

  .dataView{
    margin-top:15px;
  }
  
  .adrFormContainer {
    height: calc(100vh * 0.9 - 130px);
  }
</style>
