<template>
  <DxDataGrid
    :data-source="dataSource"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true">
    <DxScrolling mode="virtual"/>
    <DxSorting mode="none"/>
      <DxColumn
          data-field="transTime"
          caption="조회 시간">
      </DxColumn>
  </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
          DxScrolling,
          DxSorting,
          DxColumn} from "devextreme-vue/data-grid";
  import fn from "../../utils/function";
  import dsu from "../../utils/dataSourceUtils";
  export default {
    components: {
      DxDataGrid,
      DxScrolling,
      DxSorting,
      DxColumn
    },
    data() {
      return {
        dataSource: []
      }
    },
    props: {
      recId: String
    },
    mounted(){
      this.refreshData();
    },
    methods:{
      refreshData(){
        dsu.getAxiosOBJGet('/api/MRPM/selectUserRecordSearchHst', {recId:  this.recId}).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.dataSource = response.data;
          }else{
            fn.notifyError(response.msg);
          }
        });
      }
    }
   
  };
</script>

<style scoped>

</style>