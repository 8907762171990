<template>
  <div class="content-block dx-card responsive-paddings">
      <DxToolbar>
        <DxItem :options="dateBoxOptionsStart"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>        
        <DxItem :options="dateBoxOptionsEnd"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>   
        <DxItem :options="buttonOptionsSearch"
                location="center"
                locate-in-menu="never"
                widget="dxButton"/>                    
      </DxToolbar>
  </div>
  
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      key-expr="recId"
      :focused-row-enabled="true">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="virtual"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true" />

      <DxColumn
        data-field="transTime"
        caption="날짜"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="hospitalNameCode"
        caption="요양기관"
        :header-filter="{ allowSearch: true }">
      </DxColumn>
      
      <DxColumn
        data-field="url"
        caption="URL"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="urlDesc"
        caption="URL Description"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="requestMsg"
        caption="Request Message"
        width = "450"
        cell-template="text-area-template"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="errorMsg"
        width = "450"
        caption="Error Message"
        cell-template="text-area-template"
        :header-filter="{ allowSearch: true }">
      </DxColumn>
      
      <template #text-area-template="{data}">
          <TextAreaTemplate :text="data.value"/>
        </template>  
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
        DxColumn,
        DxScrolling,
        DxSorting,
        DxHeaderFilter,
        DxSearchPanel,
        DxExport} from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
  import TextAreaTemplate from "../_common/TEXT_AREA_TMPLATE";
  
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  var defaultStartDate = new Date(year, month, 1);
  var defaultEndDate = new Date(year, month + 1, 0);

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      DxToolbar,
      DxItem,
      DxExport,
      TextAreaTemplate
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: [],
        searchCondition: {startDate: defaultStartDate, endDate: defaultEndDate},
        dateBoxOptionsStart: {
          width: 190,
          value: defaultStartDate,
          text: "시작",
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 190,
          value: defaultEndDate,
          text: "시작",
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }               
      }
    },
    mounted(){
      this.search();  
    },
    computed: {
      grid: function () {
        return this.$refs[this.gridRefName].instance;
      }
    },
    methods: {
        search(){
          var params = JSON.parse(JSON.stringify(this.searchCondition));
          params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
          params.endDate = dsu.getFormatDate(this.searchCondition.endDate);

          this.grid.beginCustomLoading("데이터 조회 중...");
          dsu.getAxiosOBJGet('/api/AIER/selectIFErrorHst', params).then(res=>{
            this.grid.endCustomLoading();
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.setGridData(response.data);
            }else{
              fn.notifyError(response.msg);
            }
          });
        },
        setGridData(data)
        {
          const grid = this.$refs[this.gridRefName].instance;
          grid.option('keyExpr', 'transTime');
          grid.option('dataSource', data);
        }
      }
    };
</script>

<style scoped>
  #grid{
    max-height: calc(100vh - 330px);
  }
</style>