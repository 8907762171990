<template >
  <div class="content-block dx-card responsive-paddings">
    <DxForm :ref="searchFormRefName" label-location="left" :form-data="formData">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />

      <DxSimpleItem data-field="atcSearchLike" editor-type="dxTextBox"
        :editor-options="{ valueChangeEvent: 'input', onEnterKey: searchData }">
        <DxLabel text="검색어" />
      </DxSimpleItem>

      <DxButtonItem horizontal-alignment="center" vertical-alignment="center" :buttonOptions="{
        text: '조회',
        icon: 'search',
        onClick: searchData
      }" />
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid id="gridAtc" :data-source="atcList" :show-borders="true" :show-column-lines="true"
      @cellDblClick="onCellDblClick">

      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />

      <DxColumn data-field="atcCode" caption="ATC 코드" width="110" :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn data-field="atcName" caption="ATC 이름" :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn type="buttons" caption="KD 코드 조회" :width="110">
        <DxButton text="KD 코드" icon="search" hint="KD 코드 조회" @click="searchKDInfo" />
      </DxColumn>
    </DxDataGrid>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid id="gridKd" :data-source="kdList" :show-borders="true" :show-column-lines="true"
      @cellDblClick="onCellDblClick">

      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />

      <DxColumn data-field="kdCode" caption="KD 코드" width="110" :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn data-field="kdName" caption="KD 이름" :header-filter="{ allowSearch: true }">
      </DxColumn>
    </DxDataGrid>
  </div>
</template>
   
<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxSearchPanel,
  DxButton
} from "devextreme-vue/data-grid";
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxButtonItem
} from "devextreme-vue/form";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";


export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxHeaderFilter,
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxButtonItem,
    DxSorting,
    DxButton,
    DxSearchPanel
  },
  data() {
    return {
      searchFormRefName: 'searchForm',
      formData: {},
      atcList: [],
      kdList: []
    };
  },
  methods: {
    searchData() {
      if (this.formData.atcSearchLike) {
        this.atcList = [];
        this.kdList = [];
        
        dsu.getAxiosOBJGet('/api/ADRI/selectAtc', this.formData).then(res => {
          let response = res.data;
          if (response.result == 'SUCCESS') {
            var dtExact = response.data.filter((item) => {
              return fn.convSearchString(item.atcCode) == fn.convSearchString(this.formData.atcSearchLike)
                    || fn.convSearchString(item.atcName) == fn.convSearchString(this.formData.atcSearchLike);
            });

            var dtNotExact = response.data.filter((item) => {
              return fn.convSearchString(item.atcCode) != fn.convSearchString(this.formData.atcSearchLike)
                    && fn.convSearchString(item.atcName) != fn.convSearchString(this.formData.atcSearchLike);
            });            

            this.atcList = [];
            this.atcList.push(...dtExact);
            this.atcList.push(...dtNotExact);
          } else {
            fn.notifyError(response.msg);
          }
        });

        var params = {};
        params.kdSearchLike = this.formData.atcSearchLike;

        dsu.getAxiosOBJGet('/api/ADRI/select', params).then(res => {
          let response = res.data;
          if (response.result == 'SUCCESS') {
            this.kdList = response.data;
          } else {
            fn.notifyError(response.msg);
          }
        });
      } else {
        fn.notifyError("검색조건 입력 후 조회 필요");
      }
    },
    onCellDblClick(e) {
      var drugInfo = {};

      var rowData = e.row.data;
      if (e.column.dataField.includes('kd')) {
        drugInfo.drugType = 'KD';
        drugInfo.drugCode = rowData.kdCode;
        drugInfo.drugName = rowData.kdName;

      } else {
        drugInfo.drugType = 'ATC';
        drugInfo.drugCode = rowData.atcCode;
        drugInfo.drugName = rowData.atcName;
      }

      this.$emit('setDrug', drugInfo);
    },
    searchKDInfo(e) {
      var params = {};
      params.atcCodeLike = e.row.data.atcCode;

      dsu.getAxiosOBJGet('/api/ADRI/select', params).then(res => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          this.kdList = response.data;
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    searchDataForATC(sourceDrugData) {
      this.atcList = [];
      this.kdList = [];

      var params = {};

      params.searchDrugCode = sourceDrugData;

      dsu.getAxiosOBJPost('/api/ADRI/selectAtcSC', params).then(res => {
        let response = res.data;
        if (response.result == 'SUCCESS') {
          this.atcList = response.data;
        } else {
          fn.notifyError(response.msg);
        }
      });
    }
  }
};

</script>
   
<style scoped>
#gridAtc {
  height: 250px;
}

#gridKd {
  height: 350px;
}
</style>