<template >
 <div class="content-block dx-card responsive-paddings">
      <DxForm
        :ref="searchFormRefName"
        label-location="left"
        :form-data="formData">
        <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2"/>

        <DxSimpleItem data-field="allSearchLike"
                      editor-type="dxTextBox"
                      :editor-options="{valueChangeEvent: 'input', onEnterKey: searchData}">
                      <DxLabel text="검색어"/>
        </DxSimpleItem>      

        <DxButtonItem horizontal-alignment="center"
                      vertical-alignment="center"
                      :buttonOptions="{text: '조회',
                                      icon: 'search',
                                      onClick: searchData}"/>
      </DxForm>
 
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @editorPreparing="onEditorPreparing($event)"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @rowDblClick="onRowDblClick">
      
      <DxSearchPanel :visible="isEditingMode"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="virtual"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="isEditingMode"/>
      <DxEditing :allow-updating="isEditingMode"
                :allow-adding="isEditingMode"
                :allow-deleting="isEditingMode"
                mode="popup"/>
      
      <DxColumn v-if="isEditingMode"
                type="buttons">
        <DxButton name="edit"/>
      </DxColumn>
      
      <DxColumn
        data-field="symptomCode"
        caption="증상 코드"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
            pattern="^[A-Za-z0-9-_]{1,20}$"
            message="영문 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 20자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="symptomNameKor"
        caption="증상(한글)"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,200}$"
          message="200자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="symptomNameEng"
        caption="증상(영문)"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,200}$"
          message="200자 까지 등록 가능"/>
      </DxColumn>      
    
      <DxMasterDetail
        :enabled="isEditingMode"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/ASMT/selectHistory`"
                  :keyName="code"
                  :columns="historyColumns"/>
        </template>    
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import {DxForm,
          DxColCountByScreen,
          DxSimpleItem,
          DxLabel,
          DxButtonItem} from "devextreme-vue/form";
  import History from "../_common/SIMPLE_HISTORY";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxForm,
      DxColCountByScreen,
      DxSimpleItem,
      DxLabel,
      DxButtonItem
    },
    props: {
      isEditingMode: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        searchFormRefName: 'searchForm',
        formData: {},
        gridRefName: 'grid',
        dataSource: dsu.getComDSGrid('/api/ASMT', 'symptomCode', '?symptomCode=99999999999999999', '증상 코드'),
        historyColumns: [{dataField: "transTime", caption : "변경 시간"},
                         {dataField: "userId", caption: "이름"},
                         {dataField: "symptomNameKor", caption: "증상(한글)"},
                         {dataField: "symptomNameEng", caption: "증상(영문)"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onEditorPreparing: function (e) { 
        if (e.dataField == 'symptomCode'){
          e.editorOptions.inputAttr = {'style':  "text-transform: uppercase"};
        }
      },
      onRowInserting(e){
        e.data.symptomCode = e.data.symptomCode.toUpperCase();
      },
      onRowUpdating(e){
        if(e.newData.atcCode){
          e.newData.symptomCode = e.newData.symptomCode.toUpperCase();
        }
      },
      searchData(){
         if(this.formData.allSearchLike){
          var params =  new URLSearchParams(this.formData).toString();
          params = '?' + params;
          this.$refs[this.gridRefName].instance.option('dataSource', dsu.getComDSGrid('/api/ASMT', 'symptomCode', params, '증상 코드'));
        }else{
          fn.notifyError("검색조건 입력 후 조회 필요");
        }
      },
      onRowDblClick(e){
        this.$emit('setSymptom', e.data);
      }
    }
  };

</script>

<style scoped>
  #grid{
    height: 600px;
  }
</style>