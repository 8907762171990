<template>
  <DxSelectBox :ref="drugInputTypeRefName" v-if="isTemplateMode" :items="inputTypeList" displayExpr="name" valueExpr="id"
    width="280" @valueChanged="onInputTypeChanged" :showClearButton="true" />

  <DxDataGrid v-show="isVisibleDrugInfo" :showColumnHeaders="false" :data-source="targetList" :columnAutoWidth="true"
    :show-borders="true" :show-column-lines="true" @row-removed="onRowRemoved($event)">
    <DxToolbar>
      <DxItem location="after" widget="dxButton"
        :options="{ icon: 'edit', text: '수정', stylingMode: 'outlined', onClick: showModify }" />
    </DxToolbar>

    <DxScrolling mode="infinite" />
    <DxSelection mode="none" />
    <DxEditing :allow-updating="false" :allow-adding="false" :allow-deleting="true" />

    <DxColumn type="buttons" width="40">
      <DxButton name="edit" />
    </DxColumn>

    <DxColumn data-field="drug" cell-template="drug-cell-template" />
    <template #drug-cell-template="{ data }">
      <div class="drugTypeCode">{{ data.data.drugType }}, {{ data.data.drugCode }}</div>
      <div class="drugName">{{ data.data.drugName }}</div>
    </template>
  </DxDataGrid>

  <DxPopup :ref="popupRefName" height="auto" width="90%" :hideOnParentScroll="false" :drag-enabled="false"
    :hideOnOutsideClick="true" :showTitle="true" :title="title">

    <DxForm :ref="formRefName" label-mode="hidden">
      <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2" />

      <DxSimpleItem>
        <DIA :ref="drugInfoAtcRefName" @setDrug="setDrug" />
      </DxSimpleItem>

      <DxSimpleItem>
        <div class="content-block dx-card responsive-paddings">
          <DxDataGrid :data-source="sourceList" :columnAutoWidth="true" :show-borders="true" :show-column-lines="true"
            key-expr="drugCode">
            <DxScrolling mode="infinite" />
            <DxSelection mode="single" />
            <DxEditing :allow-updating="false" :allow-adding="false" :allow-deleting="true" mode="popup" />

            <DxColumn type="buttons">
              <DxButton name="edit" />
            </DxColumn>

            <DxColumn data-field="drugType" caption="약물 구분">
            </DxColumn>

            <DxColumn data-field="drugCode" caption="약물 코드">
            </DxColumn>

            <DxColumn data-field="drugName" caption="약물 이름">
            </DxColumn>
          </DxDataGrid>
        </div>
      </DxSimpleItem>

      <DxEmptyItem />

      <DxGroupItem col-count="2">
        <DxButtonItem>
          <DxButtonOptions text="저장" width="100%" type="default" @click="save">
          </DxButtonOptions>
        </DxButtonItem>

        <DxButtonItem>
          <DxButtonOptions text="취소" width="100%" type="normal" @click="cancel">
          </DxButtonOptions>
        </DxButtonItem>
      </DxGroupItem>
    </DxForm>
  </DxPopup>
</template>
<script>

import {
  DxDataGrid,
  DxSelection,
  DxScrolling,
  DxColumn,
  DxEditing,
  DxToolbar,
  DxItem
} from 'devextreme-vue/data-grid';
import DxPopup from "devextreme-vue/popup";
import {
  DxForm,
  DxColCountByScreen,
  DxGroupItem,
  DxSimpleItem,
  DxButtonItem,
  DxButtonOptions,
  DxEmptyItem
} from "devextreme-vue/form";
import DxSelectBox from 'devextreme-vue/select-box';
import DIA from "./DRUG_INFO_ATC";
import fn from "../../utils/function";

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn,
    DxEditing,
    DxPopup,
    DxForm,
    DxGroupItem,
    DxColCountByScreen,
    DxSimpleItem,
    DxButtonItem,
    DxButtonOptions,
    DIA,
    DxEmptyItem,
    DxToolbar,
    DxItem,
    DxSelectBox
  },
  props: {
    title: String,
    drugData: String,
    sourceDrugData: String,
    isViewMode: Boolean,
    isTemplateMode: Boolean,
    drugInputType: String,
    index: Number
  },
  data() {
    return {
      popupRefName: 'popup',
      formRefName: 'form',
      drugInfoAtcRefName: 'drugInfoRefName',
      targetList: [],
      sourceList: [],
      inputTypeList: [{ id: 'TYPE1', name: '약물 코드 지정' },
      { id: 'TYPE2', name: '원인약물 ATC' },
      { id: 'TYPE3', name: '원인약물 ATC(상위 1단계)' },
      { id: 'TYPE4', name: '원인약물 ATC(상위 2단계)' }],
      isVisibleDrugInfo: true,
      drugInputTypeRefName: "drugInputTypeRefName"
    };
  },
  mounted() {
    this.refreshTargetList();
    this.refreshInputType();
  },
  watch: {
    drugData() {
      this.refreshTargetList();
    },
    drugInputType() {
      this.refreshInputType();
    }
  },
  computed: {
    diSelectBox: function () { return this.$refs[this.drugInputTypeRefName].instance; }
  },
  methods: {
    refreshTargetList() {
        if (this.index != null && this.index != undefined) {
          this.$emit('getDrugData', this.setTargetList, this.index);  
        }else{
          this.$emit('getDrugData', this.setTargetList);
        }
    },
    setTargetList(data) { 
      this.targetList = JSON.parse(data);
    },
    refreshInputType() {
      if (this.isTemplateMode) {
        if (this.drugInputType == 'TYPE1') {
          this.isVisibleDrugInfo = true;
        } else {
          this.isVisibleDrugInfo = false;
        }

        if (this.drugInputType) {
          this.diSelectBox.option('value', this.drugInputType);
        } else {
          this.diSelectBox.reset();
        }
      }
    },
    showModify() {
      this.sourceList = JSON.parse(JSON.stringify(this.targetList));
      // this.$refs[this.drugInfoAtcRefName].searchDataForATC(this.sourceDrugData);
      this.$emit('getSourceDrugData', this.$refs[this.drugInfoAtcRefName].searchDataForATC);
      this.$refs[this.popupRefName].instance.show();
    },
    save() {
      this.targetList = JSON.parse(JSON.stringify(this.sourceList));
      this.$refs[this.popupRefName].instance.hide();
      this.$emit('setDrugData', JSON.parse(JSON.stringify(this.sourceList)),  this.index);
    },
    cancel() {
      this.$refs[this.popupRefName].instance.hide();
    },
    setDrug(drug) {
      var isDup = this.sourceList.find((row) => JSON.stringify(row) == JSON.stringify(drug));
      if (!isDup) {
        this.sourceList.push(drug);
        this.sourceList = JSON.parse(JSON.stringify(this.sourceList));
      } else {
        fn.notifyError("이미 추가된 약물");
      }
    },
    onRowRemoved() {
      this.$emit('setDrugData', JSON.parse(JSON.stringify(this.targetList)), this.index);
    },
    onInputTypeChanged(e) {
      this.$emit('setDrugInputType', e.value);
      if (e.value == "TYPE1") {
        this.isVisibleDrugInfo = true;
      }
      else {
        this.isVisibleDrugInfo = false;
        this.sourceList = [];
        this.save();
      }
    }
  }
};
</script>

<style scoped>
.drugTypeCode {
  font-weight: 1000;
}

.drugName {
  padding-left: 5px;
}
</style>