import CustomStore from 'devextreme/data/custom_store';
import store from '../store';
import axios from 'axios';
import 'whatwg-fetch';

import fn from '../utils/function'

export default {
  getComDSGrid(URL, keyName, selectParams, keyNameString) {
    if (!selectParams) {
      selectParams = '';
    }

    return new CustomStore({
      key: keyName,
      load: () => this.sendRequest(`${URL}/select` + selectParams),
      insert: (values) => this.sendRequest(`${URL}/insert`, 'POST', values, keyName),
      update: (key, values) => this.sendRequest(`${URL}/update`, 'PUT', values, keyName, key, keyNameString),
      remove: (key, values) => this.sendRequest(`${URL}/delete`, 'delete', values, keyName, key)
    })
  },
  sendRequest(url, method, data, keyName, keyValue, keyNameString) {
    method = method || 'GET';
    data = data || {};

    if (method == 'PUT' && data[keyName]) {
      if (data[keyName] != keyValue) {
        return new Promise(() => { throw keyNameString + ' 값은 변경 불가, 삭제 후 재등록 필요'; });
      }
    }

    if (keyName && keyValue) {
      data[keyName] = keyValue;
    }

    if (data[keyName]) {
      data[keyName] = data[keyName].toUpperCase();
    }

    data['userId'] = store.getters.getUserId;

    let params = Object.keys(data).map((key) => {

      if (key.toUpperCase().includes('DATE')) {
        if (typeof (data[key]) === 'string') {
          data[key] = data[key].replaceAll('/', '');
        }
        else {
          data[key] = this.getFormatDate(data[key]);
        }
      }

      return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
    }).join('&');

    if (method === 'GET') {
      return fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'token': store.getters.getToken
          , 'userId': store.getters.getUserId
        },
        credentials: 'include'
      }).then(result => result.json().then(json => {
        if (result.ok) {
          if (json.result == 'SUCCESS') {
            return json.data;
          }
          else {
            throw json.msg;
          }
        }
        else {
          throw json.Message;
        }

      }));
    }
    else {
      return fetch(url, {
        method: method,
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          'token': store.getters.getToken,
          'userId': store.getters.getUserId
        },
        credentials: 'include'
      }).then(result => {
        if (result.ok) {
          return result.text().then(text => {
            var json = JSON.parse(text);
            if (json.result == 'SUCCESS') {
              fn.notifySuccess(JSON.parse(text).msg);
              return text && JSON.parse(text);
            }
            else {
              throw json.msg;
            }
          }
          );
        } else {
          return result.json().then(json => {
            throw json.Message;
          });
        }
      });
    }


  },
  getFormatDate(date) {
    var rtnValue = '';

    if (date instanceof Date) {
      var year = date.getFullYear();    //yyyy
      var month = (1 + date.getMonth());          //M
      month = month >= 10 ? month : '0' + month;  //month 두자리로 저장
      var day = date.getDate();                   //d
      day = day >= 10 ? day : '0' + day;          //day 두자리로 저장

      if (year < 100 || year.length == 2) {
        rtnValue = '20' + year + '' + month + '' + day;
      } else if (year < 1000 || year.length == 3) {
        rtnValue = '2' + year + '' + month + '' + day;
      } else {
        rtnValue = year + '' + month + '' + day;
      }
    }
    else if (date) {
      rtnValue = date.replaceAll('/', '');
    }
    return rtnValue;       //'-' 추가하여 yyyy-mm-dd 형태 생성 가능
  },
  setDefaultParams(params) {
    if (!params) {
      params = {};
    }
    params.userId = store.getters.getUserId;
    params.hospitalCodeManager = store.getters.getHospitalCode;
    return params;
  },
  getHeaders() {
    return { 'Content-Type': 'application/json; charset=UTF-8', token: store.state.token, userId: store.state.userId };
  },
  getHeadersMultipart() {
    return { 'Content-Type': 'mutipart/form-data', token: store.state.token, userId: store.state.userId };
  },
  async getAxiosOBJGet(url, params) {
    return await axios.get(url, { params: this.setDefaultParams(params), headers: this.getHeaders() });
  },
  async getAxiosOBJGetWithOutHeader(url, params) {
    return await axios.get(url, { params: params });
  },
  getAxiosOBJPost(url, params) {
    return axios.post(url, this.setDefaultParams(params), { headers: this.getHeaders() });
  },
  getAxiosOBJPostMultiPart(url, params, files) {
    var formData = new FormData(files);
    params = this.setDefaultParams(params);
    formData.append("params", JSON.stringify(params));

    return axios.post(url, formData, { headers: this.getHeadersMultipart() });
  },
  getAxiosHospitalInfo(params) {
    return axios.post(`/api/ABIF/select`, '', { headers: this.getHeaders(), params: this.setDefaultParams(params) });
  },
  getAxiosD2TemplateInfo(params) {
    return axios.post(`/api/MD2T/select`, '', { headers: this.getHeaders(), params: this.setDefaultParams(params) });
  },
  getAxiosD3TemplateInfo(params) {
    return axios.post(`/api/MD3T/select`, '', { headers: this.getHeaders(), params: this.setDefaultParams(params) });
  },
  getAxiosOBJPostDownload(url, params) {
    return axios.post(url, this.setDefaultParams(params), { headers: this.getHeaders(), responseType: 'blob' });
  },
}
