
<template>
  <DxScrollView height="100%" width="100%"  class="with-footer single-card">
    <div class="dx-card content">
        <div class="header">
          <img class="logo" :src="require('@/assets/ci.png')">  
        </div>
          
        <div class="notice">
          <b>향후 서비스 예정</b>
          <br><br>
          <div class="text">
            약물 부작용정보 그리고 복약지도 서비스를 앱 형태로 제공될 예정입니다.
          </div>
        </div>
     </div>
  </DxScrollView>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";

export default {
  components: {
    DxScrollView
  },
};
</script>

<style lang="scss">


.notice{
  font-size: 15px;
  text-align: left;
  .text{
    text-indent: 0.3em;
    text-align: left;
  }
}

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 400px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;
      text-align: center;
     
      .logo{
        width: 70%;
      }
    }
  }
}

</style>
