<template>
    <DxDataGrid
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
>
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="false"/>
      <DxScrolling mode="virtual"/>
         <DxColumn
            data-field="d1OccurenceDate"
            caption="발생일"
            width="100">
        </DxColumn>
        
        <DxColumn
            data-field="d1Causality"
            caption="인과성">
        </DxColumn>

        <DxColumn
            data-field="d1Severity"
            caption="중증도">
        </DxColumn>

        <DxColumn
            data-field="d1Symptom"
            caption="증상"
            width="300"
            cell-template="symptom-cell-template">
        </DxColumn>

        <DxColumn
            data-field="d1CausativeDrug"
            caption="원인약물"
            width="300"
            cell-template="drug-cell-template">
        </DxColumn>

        <DxColumn
            data-field="d1CaseDescription"
            caption="사례_설명"
            width="300"
            cell-template="text-area-template">
        </DxColumn>
        <template #symptom-cell-template="{data}">
            <GridSymptom :symptomData="data.value"/>
        </template>        
        
        <template #drug-cell-template="{data}">
            <GridDrug :drugData="data.value"/>
        </template>    

        <template #text-area-template="{data}">
          <TextAreaTemplate :text="data.value"/>
        </template>              
    </DxDataGrid>
</template>

<script>
  import {DxDataGrid,
         DxColumn,
         DxScrolling} from "devextreme-vue/data-grid";       
  import GridSymptom from "../_common/GRID_SYMPTOM";
  import GridDrug from "../_common/GRID_DRUG";
  import TextAreaTemplate from "../_common/TEXT_AREA_TMPLATE";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    GridSymptom,
    GridDrug,
    TextAreaTemplate
  },
  data() {
    return {
      gridRefName: 'grid',
      dataSource: []
    }
  },
  props: {
    d1ListData: String
  },
  mounted() {
    this.dataSource = JSON.parse(this.d1ListData);
  }
}
</script>

<style scoped>
</style>