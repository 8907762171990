
<template>
  <DxScrollView class="cntr" v-if="showADRData" height="100%" width="100%">
     <div><DxButton v-if="recId"
              class="btn"
              :width="200"
              text="다운로드"
              type="normal"
              styling-mode="contained"
              icon="download"
              @click="downloadCard($event)"/>
              </div>
    <img v-if="recId" class="cardImageDesktop" :src="getImageURL(this.url)" />
    <img v-if="url" class="cardImageMobile" :src="getImageURL(this.url)" />
  </DxScrollView>
  
  <DxScrollView v-if="showInCorrectURL" height="100%" width="100%"  class="with-footer single-card">
    <div class="dx-card content">
        <div class="header">
          <img class="logo" :src="require('@/assets/ci.png')">  
        </div>
          
        <div class="notice">
          <b>잘못된 접근 입니다.</b>
          <br><br>
          <div class="text">
            URL 확인 후 다시 시도해 주세요.
          </div>
          <br><br> 
        </div>
     </div>
  </DxScrollView>
</template>

<script>
import DxScrollView from "devextreme-vue/scroll-view";
import DxButton from 'devextreme-vue/button';
import dsu from '@/utils/dataSourceUtils';

  export default {
    components: {
      DxScrollView,   
      DxButton
  },
    props: {
      recId: String
    },
    data() {
        return {
          showADRData: true,
          showInCorrectURL: false,
          url: '',
        }
    },
    beforeMount(){
      this.url = this.$route.query.z;
      if(this.url || this.recId){  
        this.showADRData = true;
        this.showInCorrectURL = false;
      }else{
        this.showADRData = false;
        this.showInCorrectURL = true;
      }
      
    },
    methods:{
      getImageURL(url) {
        if (url) {
          return encodeURI("/displayCard?url=" + url);  
        } else if (this.recId) {
          return encodeURI("/displayCard?recId=" + this.recId);  
        }
      },
      downloadCard(e){
        e.component.option('disabled', true);
        dsu.getAxiosOBJPostDownload("/downloadCard", {recId: this.recId}).then(response => {
          const name = this.recId + '_의약품 안전 카드.png';
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/png'}));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          link.style.cssText = "display:none";
          document.body.appendChild(link);
          link.click();
          link.remove();
          e.component.option('disabled', false);
        });
      },
    }
   
  };
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

.notice{
  font-size: 15px;
  text-align: center;
  .text{
    text-indent: 0.3em;
  }
}

.cntr{
   text-align: center;

  .btn{
    margin: 20px;
  }

  .cardImageMobile{
    width: 95%;  
    object-fit: cover;
  }
  
  .cardImageDesktop {
    width: 600px;
  }
}

.single-card {
  width: 100%;
  height: 100%;

  .dx-card {
    width: 400px;
    margin: auto auto;
    padding: 40px;
    flex-grow: 0;

    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      margin-bottom: 30px;
      text-align: center;
     
      .logo{
        width: 70%;
      }
    }
  }
}

</style>
