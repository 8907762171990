<template >
 <div class="content-block dx-card responsive-paddings">
      <DxForm
        :ref="searchFormRefName"
        label-location="left"
        :form-data="formData">
        <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2"/>

        <DxSimpleItem data-field="allSearchLike"
                      editor-type="dxTextBox"
                      :editor-options="{valueChangeEvent: 'input', onEnterKey: searchData}">
                      <DxLabel text="검색어"/>
        </DxSimpleItem>

        <DxButtonItem horizontal-alignment="center"
                      vertical-alignment="center"
                      :buttonOptions="{text: '조회',
                                      icon: 'search',
                                      onClick: searchData}"/>
      </DxForm>
 
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @editorPreparing="onEditorPreparing($event)"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @cellDblClick="onCellDblClick">
      
      <DxSearchPanel :visible="isEditingMode"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="isEditingMode"/>
      <DxEditing :allow-updating="isEditingMode"
                :allow-adding="isEditingMode"
                :allow-deleting="isEditingMode"
                mode="popup"/>
      
      
      <DxColumn v-if="isEditingMode"
                type="buttons">
        <DxButton name="edit"/>
      </DxColumn>
            
      <DxColumn
        data-field="kdCode"
        caption="KD 코드"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
            pattern="^[A-Za-z0-9-_]{1,30}$"
            message="영문 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 30자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="kdName"
        caption="KD 이름"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,200}$"
          message="200자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="atcCode"
        caption="ATC 코드"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
            pattern="^[A-Za-z0-9-_]{1,30}$"
            message="영문 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 30자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="atcName"
        caption="ATC 이름"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,200}$"
          message="200자 까지 등록 가능"/>
      </DxColumn>
    
      <DxMasterDetail
        :enabled="isEditingMode"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/ADRI/selectHistory`"
                  :keyName="code"
                  :columns="historyColumns"/>
        </template>    
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import {DxForm,
          DxColCountByScreen,
          DxSimpleItem,
          DxLabel,
          DxButtonItem} from "devextreme-vue/form";
  import History from "../_common/SIMPLE_HISTORY";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History,
      DxForm,
      DxColCountByScreen,
      DxSimpleItem,
      DxLabel,
      DxButtonItem
    },
    props: {
      isEditingMode: {
        type: Boolean,
        default: true
      }
    },    
    data() {
      return {
        searchFormRefName: 'searchForm',
        formData: {},
        gridRefName: 'grid',
        dataSource: dsu.getComDSGrid('/api/ADRI', 'kdCode', '?allSearchLike=99999999999999999', 'KD 코드'),
        historyColumns: [{dataField: "transTime", caption : "변경 시간"},
                         {dataField: "userId", caption: "이름"},
                         {dataField: "kdName", caption: "KD 이름"},
                         {dataField: "atcCode", caption: "ATC 코드"},
                         {dataField: "atcName", caption: "ATC 이름"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onEditorPreparing: function (e) { 
        if (e.dataField == 'kdCode' || e.dataField == 'atcCode'){
          e.editorOptions.inputAttr = {'style':  "text-transform: uppercase"};
        }
      },
      onRowInserting(e){
        e.data.kdCode = e.data.kdCode.toUpperCase();
        e.data.atcCode = e.data.atcCode.toUpperCase();
      },
      onRowUpdating(e){
        if(e.newData.atcCode){
          e.newData.atcCode = e.newData.atcCode.toUpperCase();
        }
      },
      searchData(){
         if(this.formData.allSearchLike){
          var params =  new URLSearchParams(this.formData).toString();
          params = '?' + params;
          this.$refs[this.gridRefName].instance.option('dataSource', dsu.getComDSGrid('/api/ADRI', 'kdCode', params, 'KD 코드'))
        }else{
          fn.notifyError("검색조건 입력 후 조회 필요");
        }
      },
      onCellDblClick(e){
        var drugInfo = {};

        var rowData = e.row.data;
        if(e.column.dataField.includes('kd')){
          drugInfo.drugType = 'KD';
          drugInfo.drugCode = rowData.kdCode;
          drugInfo.drugName = rowData.kdName;
          
        }else{
          drugInfo.drugType = 'ATC';
          drugInfo.drugCode = rowData.atcCode;
          drugInfo.drugName = rowData.atcName;
        }

        this.$emit('setDrug', drugInfo);
      }
    }
  };

</script>

<style scoped>
  #grid{
    height: 600px;
  }
</style>