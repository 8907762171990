<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @row-updated="onRowUpdated"
      @row-removed="onRowRemoved">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxEditing :allow-updating="false"
                 :allow-adding="true"
                 :allow-deleting="true"
                 mode="popup"/>

      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>
      
      <DxColumn
        data-field="drugCode"
        caption="약물 코드"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^[A-Z0-9]{1,30}$"
          message="영문 대문자 숫자 로만 구성, 30자 까지 등록 가능"
        />
      </DxColumn>
    
      <DxColumn
        data-field="cardType"
        caption="카드 종류">  
        <DxRequiredRule/>
        <DxLookup :data-source="[{ID: '조영제', Name: '조영제'}]"
                  value-expr="ID"
                  display-expr="Name"/>
      </DxColumn>
     
      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/APRT/selectHistory`"
                  :columns="historyColumns"/>
        </template>    
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxLookup,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
    
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxLookup,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: dsu.getComDSGrid('/api/APRT', 'drugCode', '', '약물 코드'),
        popupSrearchRefName: 'popup',
        historyColumns: [{dataField: "transTime", caption : "변경 시간"},
                         {dataField: "cardType", caption: "카드 종류"},
                         {dataField: "userId", caption: "변경 사용자"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onEditorPreparing: function (e) { 
        if (e.dataField == 'drugCode'){
          e.editorOptions.inputAttr = {'style':  "text-transform: uppercase"};
        }
      },
      onRowInserting(e){
        e.data.drugCode = e.data.drugCode.toUpperCase();
      },
      onRowUpdating(e){
        if(e.newData.drugCode){
          e.newData.drugCode = fn.parseCode(e.newData.drugCode);
        }
      }
    }
  };
</script>

<style scoped>
  #grid{
    max-height: calc(100vh - 210px);
  }
</style>