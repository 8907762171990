<template>
  <DxDataGrid
    :data-source="historyStore"
    :show-borders="true"
    :show-column-lines="true"
    :columns="columns"
    :columnAutoWidth="true"
    @cellDblClick="onDblClick">
    <DxSorting mode="none"/>
      <DxColumn caption="시스템 정보">
        <DxColumn
            data-field="transTime"
            caption="변경 시간"
            minWidth="165">
        </DxColumn>

        <DxColumn
            data-field="userId"
            caption="이름">
        </DxColumn>

        <DxColumn
            data-field="dml"
            caption="Action"
            minWidth="75">
        </DxColumn>

        <DxColumn
            data-field="hospitalNameCode"
            caption="요양기관">
        </DxColumn>
      </DxColumn>
 
      <DxColumn caption="등록 희망자">
        <DxColumn
            data-field="ptName"
            caption="성명">
        </DxColumn>

        <DxColumn
            data-field="ptNumber"
            caption="병원등록번호">
        </DxColumn>

        <DxColumn
            data-field="ptRrn"
            caption="생년월일">
        </DxColumn>

        <DxColumn
            data-field="ptTel"
            caption="휴대폰 번호">
        </DxColumn>

        <DxColumn
            data-field="ptEmail"
            caption="전자메일">
        </DxColumn>

        <DxColumn
            data-field="ptAddress"
            caption="주소"
            width="200"
            cell-template="text-area-template">
        </DxColumn>
      </DxColumn>

      <DxColumn caption="신청인">
        <DxColumn
            data-field="apName"
            caption="성명">
        </DxColumn>
        
        <DxColumn
            data-field="apRelationship"
            caption="관계">
        </DxColumn>

        <DxColumn
            data-field="apRrn"
            caption="생년월일">
        </DxColumn>

        <DxColumn
            data-field="apTel"
            caption="휴대폰 번호">
        </DxColumn>

        <DxColumn
            data-field="apEmail"
            caption="전자메일">
        </DxColumn>
      </DxColumn>

      <DxColumn
          data-field="d1List"
          caption="약물이상사례(개별사례)"
          :header-filter="{ allowSearch: false }"
          cell-template="d1-cell-template">
      </DxColumn>

      <DxColumn caption="약물이상사례(통합조정)">
        <DxColumn
            data-field="d1ManagementNumber"
            caption="원내_이상사례_관리번호"
            :header-filter="{ allowSearch: true }">
        </DxColumn>
        
        <DxColumn
            data-field="d2CurrentStatus"
            caption="현재상태">
        </DxColumn>

        <DxColumn
            data-field="d2Symptom"
            caption="증상"
            minWidth="200"
            :header-filter="{ allowSearch: true }"
            cell-template="symptom-cell-template">
        </DxColumn>
        
        <DxColumn
            data-field="d2CiGroup"
            caption="금기 성분(군)"
            minWidth="200"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>        

        <DxColumn
            data-field="d2DrugSideEffectsType"
            caption="유형"
            minWidth="200">
        </DxColumn>

        <DxColumn
            data-field="d2CrhiGroup"
            caption="주의 성분(군)"
            minWidth="200"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>                

        <DxColumn
            data-field="d2Causality"
            caption="인과성">
        </DxColumn>
        
        <DxColumn
            data-field="d2OaiGroup"
            caption="복용가능 성분(군)"
            minWidth="200"
            :header-filter="{ allowSearch: true }"
            cell-template="drug-cell-template">
        </DxColumn>  

        <DxColumn
            data-field="d2Severity"
            caption="중증도">
        </DxColumn>

        
        <DxColumn
            data-field="d2ConfTest"
            caption="확진검사">
        </DxColumn>

        <DxColumn
            data-field="d2ExpertOpinion"
            caption="전문가의견"  
            width="300"
            cell-template="text-area-template">
        </DxColumn>
      </DxColumn>

      <DxColumn caption="의료기관">
        <DxColumn
            data-field="d3HospitalName"
            caption="의료기관명">
        </DxColumn>

        <DxColumn
            data-field="d3HospitalCode"
            caption="요양기관등록번호">
        </DxColumn>

        <DxColumn
            data-field="d3FinalEvaluator"
            caption="최종평가자 직역">
        </DxColumn>

        <DxColumn
            data-field="d3MedicalDepartment"
            caption="최종평가자 전문과목"
            minWidth="130">
        </DxColumn>

        <DxColumn
            data-field="d3EvaluationDepartment"
            caption="평가부서">
        </DxColumn>

        <DxColumn
            data-field="d3ManagerTel"
            caption="평가부서 전화번호"
            minWidth="200">
        </DxColumn>
        <DxColumn
            data-field="d3Email"
            caption="평가부서 Email"
            minWidth="150">
        </DxColumn>
      </DxColumn>
      
      <DxColumn caption="기타">
        <DxColumn
            data-field="d4InsertTime"
            caption="최초작성일">
        </DxColumn>

        <DxColumn
            data-field="d4UpdateTime"
            caption="서식수정일">
        </DxColumn>

        <DxColumn
            data-field="status"
            caption="상태">
            <DxLookup :data-source="recStatus"
                    value-expr="ID"
                    display-expr="Name"/>    
        </DxColumn>    
      </DxColumn>

      <template #d1-cell-template="{data}">
          <AdrDataD1 :d1ListData="data.value"/>
      </template>        

      <template #symptom-cell-template="{data}">
          <GridSymptom :symptomData="data.value"/>
      </template>          

      <template #drug-cell-template="{data}">
          <GridDrug :drugData="data.value"/>
      </template>  
      
      <template #text-area-template="{data}">
        <TextAreaTemplate :text="data.value"/>
      </template>   
  </DxDataGrid>
</template>

<script>
  import { DxDataGrid,
           DxSorting,
           DxColumn,
           DxLookup } from "devextreme-vue/data-grid";
  import AdrDataD1 from "../_common/ADR_DATA_D1";          
  import GridSymptom from "../_common/GRID_SYMPTOM";           
  import GridDrug from "../_common/GRID_DRUG";           
  import { createStore } from "devextreme-aspnet-data-nojquery";
  import store from "../../store";
  import fn from "../../utils/function";
  import { userStatus } from "../../utils/syscodedata";
  import TextAreaTemplate from "../_common/TEXT_AREA_TMPLATE";

  export default {
    components: {
      DxDataGrid,
      DxSorting,
      DxColumn,
      DxLookup,
      AdrDataD1,
      GridSymptom,
      GridDrug,
      TextAreaTemplate
    },
    data() {
      return {
        recStatus: userStatus
      }
    },    
    props: {
      masterDetailData: {
          type: Object,
          default: () => ({})
      }
    },
    computed: {
      historyStore() {
        return this.masterDetailData.key === null ? null : {
          store: createStore({
            loadUrl: "/api/MRPM/selectADRDataHst",
            loadParams: {key: this.masterDetailData.key},
            onBeforeSend: (method, ajaxOptions) => {
               ajaxOptions.headers = { token: store.state.token, userId: store.state.userId  };  
            }
          })
        };
      }
    },
    methods:{
      onDblClick(e){
        fn.downLoadFile(e);
      }
    }
  };
</script>

<style scoped>

</style>