<template>
  <DxDataGrid
      :showColumnHeaders="false"
      :data-source="targetList"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true">
      <DxScrolling mode="infinite"/>
      <DxSelection mode="none"/>
      <DxColumn data-field="symptom"
                cell-template="drug-cell-template"/>
          <template #drug-cell-template="{ data }">
            <div class="drugTypeCode">{{data.data.drugType}}, {{data.data.drugCode}}</div>
            <div class="drugName">{{data.data.drugName}}</div>
          </template>   
    </DxDataGrid>
</template>
<script>

import {DxDataGrid,
        DxSelection,
        DxScrolling,
        DxColumn
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn
  },
  props: {
    drugData: String
  },
  mounted() {
    this.targetList = JSON.parse(this.drugData);
  },
  data() {
    return {
      targetList: []
    };
  }
};
</script>

<style scoped>
.drugTypeCode{
    font-weight: 1000;
}
.drugName{
  padding-left: 5px;
}
</style>