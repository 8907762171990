<template>
  <DxDataGrid
    :data-source="targetList"
    :show-borders="true"
    :show-column-lines="true"
    :wordWrapEnabled="true"
  >
    <DxScrolling mode="infinite" />
    <DxSelection mode="none" />
    <DxColumn data-field="sideEffect" caption="부작용 증상" />
  </DxDataGrid>
</template>
<script>
import {
  DxDataGrid,
  DxSelection,
  DxScrolling,
  DxColumn,
} from 'devextreme-vue/data-grid';

export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn,
  },
  props: {
    sideEffectsData: String,
  },
  mounted() {
    var arr = JSON.parse(this.sideEffectsData);
    if (arr) {
      this.targetList = arr.map((item) => ({ sideEffect: item }));
    }
  },
  data() {
    return {
      targetList: [],
    };
  },
};
</script>

<style scoped></style>
