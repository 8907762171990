<template>
  <div class="content-block dx-card responsive-paddings">
      <DxToolbar>
        <DxItem :options="dateBoxOptionsStart"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>        
        <DxItem :options="dateBoxOptionsEnd"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>   
        <DxItem :options="buttonOptionsSearch"
                location="center"
                locate-in-menu="never"
                widget="dxButton"/>                    
      </DxToolbar>
  </div>

  <div class="content-block dx-card responsive-paddings">
      <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        :focused-row-enabled="true">

        <DxSorting mode="multiple"/>
        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        
        <DxColumn caption="기관 관리자 정보">
          <DxColumn
            data-field="hospitalNameCodeManager"
            caption="요양기관">
          </DxColumn>

          <DxColumn
            data-field="userId"
            caption="기관 관리자">
          </DxColumn>
          
          <DxColumn
            data-field="action"
            caption="Action">
          </DxColumn>
      
          <DxColumn
            data-field="transTime"
            caption="시간">
          </DxColumn>
        </DxColumn>

        <DxColumn caption="환자 정보">
          <DxColumn
            data-field="hospitalNameCode"
            caption="요양기관">  
          </DxColumn>

          <DxColumn
            data-field="ptNumber"
            caption="병원등록번호">
          </DxColumn>

          <DxColumn
            data-field="ptName"
            caption="성명">
          </DxColumn>
        </DxColumn>   
      </DxDataGrid>
  </div>
</template>

<script>
  import DxToolbar, {DxItem} from "devextreme-vue/toolbar";
  import {DxDataGrid,
          DxScrolling,
          DxColumn,
          DxSearchPanel,
          DxHeaderFilter,
          DxSorting} from "devextreme-vue/data-grid";
  import fn from "../../utils/function";
  import dsu from "../../utils/dataSourceUtils";
  import {userAuth} from "../../utils/syscodedata";

  var endDate = new Date();
  var startDate = new Date();
  startDate.setDate(endDate.getDate() - 7);
  var defaultStartDate = startDate;
  var defaultEndDate = endDate;
 
  export default {
    components: {
      DxToolbar,
      DxItem,
      DxDataGrid,
      DxScrolling,
      DxColumn,
      DxSearchPanel,
      DxHeaderFilter,
      DxSorting
    },
    data() {
      return {
        userAuth: userAuth,
        gridRefName: 'dataGrid',
        searchCondition: {startDate: defaultStartDate, endDate: defaultEndDate},
        dateBoxOptionsStart: {
          width: 190,
          value: defaultStartDate,
          text: "시작",
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 190,
          value: defaultEndDate,
          text: "시작",
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }       
      };
    },
    methods: {
      search(){
        var params = JSON.parse(JSON.stringify(this.searchCondition));
        params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
        params.endDate = dsu.getFormatDate(this.searchCondition.endDate);

        dsu.getAxiosOBJGet('/api/AVML/selectUserActionHst', params).then(res=>{
          let response = res.data;
          if(response.result == 'SUCCESS'){
            this.setGridData(response.data);
          }else{
            fn.notifyError(response.msg);
          }
        });
      },
      onExporting(e) {
        e.fileName = this.$route.meta.transName;
      },
      setGridData(data)
      {
        const grid = this.$refs[this.gridRefName].instance;
        grid.option('keyExpr', 'transTime');
        grid.option('dataSource', data);
      }
    }   
  };
</script>

<style scoped>
#grid{
  max-height: calc(100vh - 330px);
}
</style>