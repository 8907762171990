<template >
 <div class="content-block dx-card responsive-paddings">
      <DxForm
        :ref="searchFormRefName"
        label-location="left"
        :form-data="formData">
        <DxColCountByScreen :xs="1" :sm="1" :md="2" :lg="2"/>

        <DxSimpleItem data-field="allSearchLike"
                      editor-type="dxTextBox"
                      :editor-options="{valueChangeEvent: 'input', onEnterKey: searchData}">
                      <DxLabel text="검색어"/>
        </DxSimpleItem>      

        <DxButtonItem horizontal-alignment="center"
                      vertical-alignment="center"
                      :buttonOptions="{text: '조회',
                                      icon: 'search',
                                      onClick: searchData}"/>
      </DxForm>
 
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      keyExpr="symptomCode"
      @editorPreparing="onEditorPreparing($event)"
      @rowDblClick="onRowDblClick">
      
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="virtual"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="isEditingMode"/>
      <DxEditing :allow-updating="isEditingMode"
                :allow-adding="isEditingMode"
                :allow-deleting="isEditingMode"
                mode="popup"/>
      
      <DxColumn v-if="isEditingMode"
                type="buttons">
        <DxButton name="edit"/>
      </DxColumn>
      
      <DxColumn
        data-field="symptomCode"
        caption="증상 코드"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
            pattern="^[A-Za-z0-9-_]{1,20}$"
            message="영문 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 20자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="symptomNameKor"
        caption="증상(한글)"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,200}$"
          message="200자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="symptomNameEng"
        caption="증상(영문)"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{1,200}$"
          message="200자 까지 등록 가능"/>
      </DxColumn>      
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxRequiredRule,
          DxPatternRule} from "devextreme-vue/data-grid";
  import {DxForm,
          DxColCountByScreen,
          DxSimpleItem,
          DxLabel,
          DxButtonItem} from "devextreme-vue/form";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxRequiredRule,
      DxPatternRule,
      DxForm,
      DxColCountByScreen,
      DxSimpleItem,
      DxLabel,
      DxButtonItem
    },
    props: {
      isEditingMode: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        searchFormRefName: 'searchForm',
        formData: {},
        gridRefName: 'grid',
        dataSource: '',
      };
    },
    methods:{
      searchData(){
        if (this.formData.allSearchLike) {
          this.atcList = [];
          this.kdList = [];
          
          dsu.getAxiosOBJGet('/api/ASMT/select', this.formData).then(res => {
            let response = res.data;
            if (response.result == 'SUCCESS') {
              var dtExact = response.data.filter((item) => {
                return fn.convSearchString(item.symptomCode) == fn.convSearchString(this.formData.allSearchLike)
                      || fn.convSearchString(item.symptomNameKor) == fn.convSearchString(this.formData.allSearchLike)
                      || fn.convSearchString(item.symptomNameEng) == fn.convSearchString(this.formData.allSearchLike);
              });

              var dtNotExact = response.data.filter((item) => {
                return fn.convSearchString(item.symptomCode) != fn.convSearchString(this.formData.allSearchLike)
                      && fn.convSearchString(item.symptomNameKor) != fn.convSearchString(this.formData.allSearchLike)
                      && fn.convSearchString(item.symptomNameEng) != fn.convSearchString(this.formData.allSearchLike);
              });            

              this.dataSource = [];
              this.dataSource.push(...dtExact);
              this.dataSource.push(...dtNotExact);
            } else {
              fn.notifyError(response.msg);
            }
          });
        }else{
            fn.notifyError("검색조건 입력 후 조회 필요");
        }
      },
      onRowDblClick(e){
        this.$emit('setSymptom', e.data);
      }
    }
  };

</script>

<style scoped>
  #grid{
    height: 600px;
  }
</style>