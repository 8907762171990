<template>
  <DxDropDownBox
    :ref="dropDownBoxRefName"
    :drop-down-options="dropDownOptions"
    :data-source="[currentValue]"
    :value="currentValue"
    content-template="contentTemplate">
    <DxValidator validation-group="customValidation">
      <DxRequiredRule/> 
    </DxValidator>
    
    <template #contentTemplate="{}">
      <DxDataGrid
                id="grid"
                height="395px"
                :ref="gridRefName"
                :data-source="dataSourceGrid"
                :columnAutoWidth="true"
                :show-borders="true"
                :show-column-lines="true"
                @toolbar-preparing="onToolbarPreparing($event)"
                @rowClick="onClick">
                <DxScrolling mode="infinite"/>
                <DxSelection mode="single"/>
                <DxColumn
                    data-field="nameCode"
                    caption="요양기관"
                    :header-filter="{ allowSearch: true }"/>
            </DxDataGrid>
    </template>
  </DxDropDownBox>
</template>
<script>

import {
  DxDataGrid,
  DxSelection,
  DxScrolling,
  DxColumn,
} from 'devextreme-vue/data-grid';
import DxDropDownBox from 'devextreme-vue/drop-down-box';
import dsu from '../../utils/dataSourceUtils';
import fn from '../../utils/function';
import DxValidator, {DxRequiredRule} from 'devextreme-vue/validator';


export default {
  components: {
    DxDataGrid,
    DxSelection,
    DxScrolling,
    DxColumn,
    DxDropDownBox,
    DxValidator,
    DxRequiredRule
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    onValueChanged: {
      type: Function,
      default: () => function() {},
    },
  },
  data() {
    return {
      currentValue: this.value,
      dropDownOptions: { width: 400 },
      dropDownBoxRefName: 'dropDownBoxRef',
      dataSourceGrid: [],
      searchValue: ''
    }
  },
  methods: {
    searchData(){
      if(!this.searchValue){
        fn.notifyError("검색어 입력 후 진행 필요");
        return;
      }
      var params ={name: this.searchValue}
      dsu.getAxiosOBJGet(' /api/ABIF/selectHospitalInfoForSearch', params).then(res=>{
        let response = res.data;
        if(response.result == 'SUCCESS'){
          if(response.data.length > 0){
            this.dataSourceGrid = response.data;
          }else{
            this.dataSourceGrid = [];
            fn.notifyError("일치하는 데이터 없음");
          }
        }else{
          fn.notifyError(response.msg);
        }
      });
    },
    onToolbarPreparing(e){
      e.toolbarOptions.items.unshift(
          {location: 'after',
            widget: 'dxTextBox',
            options:{
            valueChangeEvent: 'input',
            onValueChanged: async (e) => { this.searchValue = await e.value;},
            onEnterKey: this.searchData
          }},
          {location: 'after',
          widget: 'dxButton',
          options: {
              icon: 'search',
              text: '검색',
              stylingMode:"outlined",
              onClick: this.searchData
          }}
      );
    },
    onClick(e){
      this.currentValue = e.data.nameCode;
      this.onValueChanged(e.data.nameCode);
      this.$refs[this.dropDownBoxRefName].instance.close();
    }
  }
};
</script>
