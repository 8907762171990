<template>
  <div class="content-block dx-card responsive-paddings">
      <DxToolbar>
        <DxItem :options="dateBoxOptionsStart"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>        
        <DxItem :options="dateBoxOptionsEnd"
                location="center"
                locate-in-menu="auto"
                widget="dxDateBox"/>   
        <DxItem :options="buttonOptionsSearch"
                location="center"
                locate-in-menu="never"
                widget="dxButton"/>                    
      </DxToolbar>
  </div>
  
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      key-expr="recId"
      :focused-row-enabled="true"
      @exporting="onExporting($event)">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="virtual"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true" />

      <DxColumn
        data-field="dt"
        caption="날짜"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="hospitalNameCode"
        caption="요양기관"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="recId"
        caption="Record ID"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="ptName"
        caption="성명"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="ptNumber"
        caption="병원등록번호"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
          data-field="d1OccurenceDate"
          caption="발생일"
          :header-filter="{ allowSearch: true }">
      </DxColumn>

       <DxColumn
          data-field="d2DrugSideEffectsType"
          caption="유형"
          minWidth="200">
      </DxColumn>

      <DxColumn
          data-field="d2Causality"
          caption="인과성">
      </DxColumn>
        
      <DxColumn
          data-field="d2Severity"
          caption="중증도">
      </DxColumn>

      <DxColumn
          data-field="d1CausativeDrug"
          caption="원인약물"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>

      <DxColumn
          data-field="d1Symptom"
          caption="증상"
          :header-filter="{ allowSearch: true }"
          cell-template="symptom-cell-template">
      </DxColumn>

      <DxColumn
          data-field="d2CiGroup"
          caption="금기 성분(군)"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>      
        
      <DxColumn
          data-field="d2CrhiGroup"
          caption="주의 성분(군)"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>     
                  
      <DxColumn
          data-field="d2OaiGroup"
          caption="복용가능 성분(군)"
          :header-filter="{ allowSearch: true }"
          cell-template="drug-cell-template">
      </DxColumn>  

      <DxColumn
          data-field="print"
          alignment="center"
          caption="약물카드 발급"
          :header-filter="{ allowSearch: true }">
      </DxColumn>    

      <DxColumn
          data-field="send"
          alignment="center"
          caption="약물카드 전송"
          :header-filter="{ allowSearch: true }">
      </DxColumn>  

      <template #symptom-cell-template="{data}">
          <GridSymptom :symptomData="data.value"/>
      </template>        
      
      <template #drug-cell-template="{data}">
          <GridDrug :drugData="data.value"/>
      </template>                
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
        DxColumn,
        DxScrolling,
        DxSorting,
        DxHeaderFilter,
        DxSearchPanel,
        DxExport} from "devextreme-vue/data-grid";
  import GridSymptom from "../_common/GRID_SYMPTOM";
  import GridDrug from "../_common/GRID_DRUG";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
  import { Workbook } from "exceljs";
  import { saveAs } from "file-saver-es";
  import { exportDataGrid } from "devextreme/excel_exporter";
  import store from "../../store";

  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  var defaultStartDate = new Date(year, month, 1);
  var defaultEndDate = new Date(year, month + 1, 0);

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxHeaderFilter,
      DxSearchPanel,
      GridSymptom,
      GridDrug,
      DxToolbar,
      DxItem,
      DxExport
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: [],
        searchCondition: {startDate: defaultStartDate, endDate: defaultEndDate},
        dateBoxOptionsStart: {
          width: 190,
          value: defaultStartDate,
          text: "시작",
          displayFormat:" 시작 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.startDate = args.value;
          }
        },   
        dateBoxOptionsEnd: {
          width: 190,
          value: defaultEndDate,
          text: "시작",
          displayFormat:" 종료 :  yyyy/MM/dd",
          onValueChanged: (args) => {
            this.searchCondition.endDate = args.value;
          }
        },
        buttonOptionsSearch: {
          icon: 'search',
          text: '조회',
          stylingMode: "outlined",
          onClick: this.search.bind()
        }               
      }
    },
    mounted(){
      this.search();  
    },
    computed: {
      grid: function () {
        return this.$refs[this.gridRefName].instance;
      }
    },
    methods: {
      onExporting(e) {
          const workbook = new Workbook();
          const worksheet = workbook.addWorksheet("data");

          exportDataGrid({
            component: e.component,
            worksheet,
            keepColumnWidths: false,
            customizeCell: ({ gridCell, excelCell }) => {
              const rowType = gridCell.rowType;
              const dataField = gridCell.column.dataField;

              if (rowType == "data") {
                var data = "";
                if (dataField == "d1CausativeDrug" || dataField == "d2CiGroup" || dataField == "d2CrhiGroup" || dataField == "d2OaiGroup") {
                  if(excelCell.value){
                    data = JSON.parse(excelCell.value);
                    excelCell.value = data.map(item => `${item.drugType}, ${item.drugCode}, ${item.drugName}`).join("\n");}
                } else if (dataField == "d1Symptom" || dataField == "d2Symptom") {
                  if (excelCell.value) {
                    data = JSON.parse(excelCell.value);
                    excelCell.value = data.map(item => `${item.symptomCode}, ${item.symptomNameKor}, ${item.symptomNameEng}`).join("\n");
                  }
                } 
              }
            },
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(
                new Blob([buffer], { type: "application/octet-stream" }),
                this.$route.meta.transName + ".xlsx"
              );
            });
          });
          e.cancel = true;
        },
        search(){
          var params = JSON.parse(JSON.stringify(this.searchCondition));
          params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
          params.endDate = dsu.getFormatDate(this.searchCondition.endDate);

          const menuAuth = JSON.parse(store.getters.getMenuList).find(
            (item) => item.transaction === "MPSH"
          );

          if (menuAuth.f1Auth == "true") {
            params.searchAll = "Y";
          } else {
            params.searchAll = "N";
          }
          this.grid.beginCustomLoading("데이터 조회 중...");
          dsu.getAxiosOBJGet('/api/MPSH/selectADRDataForPrintSend', params).then(res=>{
            this.grid.endCustomLoading();
            let response = res.data;
            if(response.result == 'SUCCESS'){
              this.setGridData(response.data);
            }else{
              fn.notifyError(response.msg);
            }
          });
        },
        setGridData(data)
        {
          const grid = this.$refs[this.gridRefName].instance;
          grid.option('keyExpr', ['dt', 'recId']);
          grid.option('dataSource', data);
        }
      }
    };
</script>

<style scoped>
  #grid{
    max-height: calc(100vh - 330px);
  }
</style>