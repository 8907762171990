<template>
  <div class="break-word-div">
    {{ text }}
  </div>
</template>

<script>

export default {
  props: {
    text: String
  }
}
</script>

<style lang="scss">
  .break-word-div {
      word-wrap: break-all;
      white-space: normal; /* 기본 값, 줄바꿈 허용 */
}
</style>