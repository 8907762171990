<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @row-inserting="onRowInserting"
      @row-updating="onRowUpdating"
      @row-updated="onRowUpdated"
      @row-removed="onRowRemoved">
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxEditing
        :allow-updating="true"
        :allow-adding="false"
        :allow-deleting="true"
        mode="popup"/>

      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="newUser"
        caption="신규 가입자"
        :allowEditing="false"
        :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="id"
        caption="ID"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule
          pattern="^[A-Z0-9]{5,20}$"
          message="영문 대문자 숫자 로만 구성, 5~20자 까지 등록 가능"
        />
      </DxColumn>

      <DxColumn
        data-field="userName"
        caption="이름"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{2,20}$" message="2~20자 까지 등록 가능" />
      </DxColumn>

      <DxColumn data-field="menuAuth" caption="권한">
        <DxRequiredRule />
        <DxLookup
          :data-source="menuAuth"
          value-expr="groupId"
          display-expr="groupName"
        />
      </DxColumn>

      <DxColumn
        data-field="hospitalNameCode"
        caption="소속(요양기관)"
        edit-cell-template="dropDownBoxEditor"
      >
        <DxRequiredRule />
      </DxColumn>

      <DxColumn data-field="job" caption="직역">
        <DxRequiredRule />
        <DxLookup :data-source="jobList" value-expr="ID" display-expr="Name" />
      </DxColumn>

      <DxColumn
        data-field="email"
        caption="Email"
        :editor-options="{ stylingMode: 'filled', mode: 'email' }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{2,50}$" message="50자까지 입력 가능" />
        <DxEmailRule message="이메일 형식 오류" />
      </DxColumn>

      <DxColumn data-field="tel" caption="연락처(기관 전화번호)" mode="tel">
        <DxRequiredRule />
        <DxPatternRule
          pattern="^0\d{1,2}-?\d{3,4}-?\d{4}$"
          message="전화번호 형식 확인 필요(일반전화번호만 가능)"
        />
        <DxRequiredRule />
      </DxColumn>

      <DxColumn
        data-field="emailCertification"
        caption="Email 인증"
        :allowEditing="false"
      >
      </DxColumn>

      <DxColumn
        data-field="otpCertification"
        caption="OTP 설정"
        :allowEditing="false"
      >
      </DxColumn>

      <DxColumn data-field="status" caption="상태">
        <DxRequiredRule />
        <DxLookup
          :data-source="userStatusList"
          value-expr="ID"
          display-expr="Name"
        />
      </DxColumn>

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <History
          :master-detail-data="data"
          :url="`/api/AUSR/selectHistory`"
          :columns="historyColumns"
        />
      </template>

      <template #dropDownBoxEditor="{ data: cellInfo }">
        <DdSearchHospital
          :value="cellInfo.value"
          :on-value-changed="cellInfo.setValue"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxEditing,
  DxSearchPanel,
  DxLookup,
  DxRequiredRule,
  DxPatternRule,
  DxMasterDetail,
  DxEmailRule,
} from "devextreme-vue/data-grid";
import History from "../_common/SIMPLE_HISTORY";
import DdSearchHospital from "../_common/DD_SEARCH_HOSPITAL";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import { job, userStatus } from "../../utils/syscodedata";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxEditing,
    DxSearchPanel,
    DxLookup,
    DxRequiredRule,
    DxPatternRule,
    DxMasterDetail,
    History,
    DxEmailRule,
    DdSearchHospital,
  },
  data() {
    return {
      formData: { belong: "" },
      gridRefName: "grid",
      dataSource: dsu.getComDSGrid("/api/AUSR", "id", "", "ID"),
      popupSrearchRefName: "popup",
      hospitalList: [],
      jobList: job,
      userStatusList: userStatus,
      menuAuth: [],
      historyColumns: [
        { dataField: "transTime", caption: "변경 시간" },
        { dataField: "userName", caption: "이름" },
        { dataField: "menuAuth", caption: "권한" },
        { dataField: "belong", caption: "소속(요양기관)" },
        {
          dataField: "job",
          caption: "직역",
          lookup: { dataSource: job, valueExpr: "ID", displayExpr: "Name" },
        },
        { dataField: "email", caption: "Email" },
        { dataField: "tel", caption: "연락처" },
        {
          dataField: "status",
          caption: "상태",
          lookup: {
            dataSource: userStatus,
            valueExpr: "ID",
            displayExpr: "Name",
          },
        },
        { dataField: "userId", caption: "변경 사용자" },
        { dataField: "dml", caption: "Action" },
      ],
    };
  },
  mounted() {
    dsu.getAxiosOBJGet("/api/AMGR/selectAuthGroupInfo").then((res) => {
      if (res.data.result == "SUCCESS") {
        this.menuAuth = res.data.data;
      } else {
        fn.notifyError(res.data.msg);
      }
    });
  },
  methods: {
    showBelongSearch() {
      this.$refs[this.popupSrearchRefName].show();
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    onEditorPreparing: function (e) {
      if (e.dataField == "id") {
        e.editorOptions.inputAttr = { style: "text-transform: uppercase" };
      }
    },
    onRowInserting(e) {
      e.data.id = e.data.id.toUpperCase();
      e.data.belong = fn.parseCode(e.data.hospitalNameCode);
    },
    onRowUpdating(e) {
      if (e.newData.hospitalNameCode) {
        e.newData.belong = fn.parseCode(e.newData.hospitalNameCode);
      }
    },
    onValueChangedId(e) {
      e.value = e.value.toUpperCase();
    },
    setBelongData(belongOrg) {
      this.formData.belong = belongOrg;
    },
    onRowUpdated() {
      this.$emit("refreshMenu");
    },
    onRowRemoved() {
      this.$emit("refreshMenu");
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 210px);
}
</style>
